import {useState, useCallback, memo, useMemo, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import PhotoSourceSelector from './PhotoSourceSelector';
import PatternAndColorSelector from './PatternAndColorSelector';
import AvatarPreview from './AvatarPreview';
import {ArrowButton} from 'components/Buttons/ArrowButton';
import {AvatarCartoonSelector} from './AvatarCartoonSelector';
import {useQueryClient} from '@tanstack/react-query';
import {generateCartoonStylesQueryKey, getPhotoId} from 'api/MLSChallenge/useGetCartoonStyles';
import {useGetCartoonStyles} from 'api/MLSChallenge/useGetCartoonStyles';
import {Button} from 'components/MLS/Button/Button';
import useUpdatePlayerCard from 'api/MLSChallenge/useUpdatePlayerCard';
import {colors} from 'components/MLS/PlayerCardBackground/PlayerCardBackground';
import {useProfiles} from 'api/User/useProfiles';
import tocaLogoBlack from '../../assets/img/icons/toca-logo-black.png';
import tocaLogoWhite from '../../assets/img/toca-logo-white.png';
import {urlToDataUrl} from './utils';

enum Step {
  PHOTO = 'Photo',
  CARTOON = 'Avatar',
  PATTERN_COLOR = 'Pattern & Color',
}

const STEPS = [Step.PHOTO, Step.CARTOON, Step.PATTERN_COLOR];

const AvatarCreator = memo(() => {
  const navigate = useNavigate();
  const {
    currentProfile: {_id: playerProfileId, email, playerCardInfo},
    refetchUserAndProfiles,
  } = useProfiles();

  const [currentStep, setCurrentStep] = useState<Step>(Step.PHOTO);
  const [selectedPattern, setSelectedPattern] = useState<number>(playerCardInfo?.patternIndex || 0);
  const [selectedColor, setSelectedColor] = useState<number>(
    playerCardInfo
      ? colors.findIndex(
          (color) =>
            color.lightColor === playerCardInfo?.playerCardLightColor &&
            color.darkColor === playerCardInfo?.playerCardDarkColor &&
            color.textColor === playerCardInfo?.playerCardTextColor
        )
      : 6
  );
  const [photoData, setPhotoData] = useState<string | null>(playerCardInfo?.imageSrc || null);
  const [cartoonPhotoUrl, setCartoonPhotoUrl] = useState<string | null>(null);
  const [selectedCartoonIndex, setSelectedCartoonIndex] = useState<number | null>(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const queryClient = useQueryClient();
  const {updatePlayerCard} = useUpdatePlayerCard({playerProfileId: playerProfileId});
  const [fallbackCartoons, setFallbackCartoons] = useState<string[]>([]);

  const {
    data: fetchedCartoonStyles,
    isLoading: isCartoonLoading,
    error: cartoonError,
  } = useGetCartoonStyles(photoData || null, currentStep === Step.CARTOON, () => {
    console.log('fetching cartoon styles');
    setSelectedCartoonIndex(0);
    setCartoonPhotoUrl(photoData ? photoData : fallbackCartoons[0]);
  });

  console.log('isCartoonLoading:', isCartoonLoading);

  const hasEnoughInfo = useMemo(() => {
    return !!cartoonPhotoUrl && selectedPattern !== null && selectedColor !== null;
  }, [cartoonPhotoUrl, selectedPattern, selectedColor]);

  const cartoonStyles = useMemo(() => {
    if (!photoData) {
      return fallbackCartoons;
    }
    return isCartoonLoading || !fetchedCartoonStyles ? [photoData] : [photoData, ...fetchedCartoonStyles];
  }, [photoData, fetchedCartoonStyles, isCartoonLoading]);

  const handleCartoonifySelect = useCallback((dataUrl: string, index: number) => {
    setCartoonPhotoUrl(dataUrl);
    setSelectedCartoonIndex(index);
  }, []);

  const handlePhotoSelect = useCallback(async (dataUrl: string) => {
    setPhotoData(dataUrl);
    setCurrentStep(Step.CARTOON);
    // Invalidate previous cartoon styles cache when new photo is selected
    if (dataUrl) {
      const photoId = getPhotoId(dataUrl);
      queryClient.invalidateQueries({
        queryKey: generateCartoonStylesQueryKey(photoId),
      });
    }
  }, []);

  const handlePatternSelect = useCallback((patternId: number) => {
    console.log('handlePatternSelect', patternId);
    setSelectedPattern(patternId);
  }, []);

  const handleColorSelect = useCallback((colorId: number) => {
    console.log('handleColorSelect', colorId);
    setSelectedColor(colorId);
  }, []);

  const handleConfirm = useCallback(async () => {
    if (!cartoonPhotoUrl) {
      setError('Please select an avatar first');
      return;
    }
    try {
      setIsLoading(true);

      // First update avatar
      await updatePlayerCard({
        patternIndex: selectedPattern,
        playerCardLightColor: colors[selectedColor].lightColor,
        playerCardDarkColor: colors[selectedColor].darkColor,
        playerCardTextColor: colors[selectedColor].textColor,
        avatarUrl: cartoonPhotoUrl,
      });
      refetchUserAndProfiles();
      navigate('/home');
    } catch (err) {
      setError('Failed to save Player Card Info. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [selectedPattern, selectedColor, cartoonPhotoUrl, navigate]);

  const canGoNext = useCallback(() => {
    if (currentStep === Step.PHOTO) return !isLoading;
    if (currentStep === Step.CARTOON) return !!cartoonPhotoUrl;
    if (currentStep === Step.PATTERN_COLOR) return hasEnoughInfo;
    return false;
  }, [currentStep, photoData, cartoonPhotoUrl, hasEnoughInfo, isLoading]);

  const showPrevButton = useCallback(() => {
    return currentStep !== Step.PHOTO || !hasEnoughInfo;
  }, [currentStep, hasEnoughInfo]);

  const getNextStep = useCallback(() => {
    const currentIndex = STEPS.indexOf(currentStep);
    if (currentIndex === STEPS.length - 1) return null;
    return STEPS[currentIndex + 1];
  }, [currentStep]);

  const getPreviousStep = useCallback(() => {
    const currentIndex = STEPS.indexOf(currentStep);
    if (currentIndex === 0) return null;
    return STEPS[currentIndex - 1];
  }, [currentStep]);

  const handleNext = useCallback(() => {
    const nextStep = getNextStep();
    if (nextStep && canGoNext()) {
      // if (currentStep === Step.PHOTO && !photoData) {
      //   setCartoonPhotoUrl(tocaLogoBlack);
      //   setSelectedCartoonIndex(0);
      // }
      setCurrentStep(nextStep);
    }
  }, [currentStep, getNextStep, canGoNext, photoData]);

  const handleBack = useCallback(() => {
    const previousStep = getPreviousStep();
    if (currentStep === Step.PHOTO) {
      // Show a warning that they will lose their progress??
      navigate('/home');
    } else if (previousStep) {
      setCurrentStep(previousStep);
    }
  }, [currentStep, getPreviousStep, navigate]);

  useEffect(() => {
    // Fetch both fallback images and store their data URLs
    const fetchFallbackCartoons = async () => {
      try {
        const dataUrls = await Promise.all([tocaLogoBlack, tocaLogoWhite].map((url) => urlToDataUrl(url)));
        setFallbackCartoons(dataUrls);
      } catch (error) {
        console.error('Failed to load fallback images:', error);
      }
    };

    fetchFallbackCartoons();
  }, []);

  return (
    <div className="exceed-global-padding flex min-h-screen flex-col overflow-auto">
      <div className="flex w-full">
        <AvatarPreview
          photoUrl={cartoonPhotoUrl || photoData || ''}
          colorIndex={selectedColor}
          patternIndex={selectedPattern}
        />
      </div>
      <div className="w-full">
        {error && (
          <div className="bg-red-100 border-red-400 text-red-700 mb-4 rounded-lg border px-4 py-3">{error}</div>
        )}

        {/* {currentStep === Step.PHOTO && hasEnoughInfo && (
          <div className="absolute left-5 top-16 z-100 rounded-lg bg-mls-primary">
            <Button label="Done" variant="dark" onClick={handleConfirm} />
          </div>
        )} */}

        {/* Navigation Arrows */}
        <div className="mt-2 grid grid-cols-[auto_1fr_auto] items-center gap-4 px-8">
          {showPrevButton() ? (
            <ArrowButton
              arrowDirection="left"
              variant="primary"
              onClick={handleBack}
              disabled={currentStep === Step.PHOTO && hasEnoughInfo}
            />
          ) : (
            <div className="w-12"></div>
          )}
          <div className="text-center text-xl">{currentStep}</div>
          {currentStep == Step.PATTERN_COLOR ? (
            <Button label="Done" variant="dark" onClick={handleConfirm} />
          ) : (
            <ArrowButton arrowDirection="right" variant="primary" onClick={handleNext} disabled={!canGoNext()} />
          )}
        </div>

        {currentStep === Step.PHOTO && (
          <PhotoSourceSelector onPhotoSelect={handlePhotoSelect} photoPresent={!!photoData} />
        )}

        {currentStep === Step.CARTOON && (
          <AvatarCartoonSelector
            selectedIndex={selectedCartoonIndex}
            cartoons={cartoonStyles || []}
            onCartoonSelect={handleCartoonifySelect}
            showLoader={isCartoonLoading && !!photoData}
            error={cartoonError}
          />
        )}

        {currentStep === Step.PATTERN_COLOR && (
          <PatternAndColorSelector
            patternIndex={selectedPattern}
            colorIndex={selectedColor}
            onPatternSelect={handlePatternSelect}
            onColorSelect={handleColorSelect}
          />
        )}
      </div>
    </div>
  );
});

AvatarCreator.displayName = 'AvatarCreator';
export default AvatarCreator;
