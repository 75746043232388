import {useNavigate, useParams} from 'react-router-dom';
import {useEffect} from 'react';
import Spinner from '../../components/Loader/Spinner';

/**
 * Rewrites the path '/player/:mboClientId/:sessionId/overall' used by the native trainer app to the
 * standard '/trainer/profile?id=:mboClientId' path.
 */
export function TrainerRewritePath() {
  const navigate = useNavigate();
  const {mboClientId, siteId} = useParams();

  useEffect(() => {
    navigate(`/trainer/connects?mboClientId=${mboClientId}&mboSiteId=${siteId}`, {replace: true});
  }, [mboClientId, siteId, navigate]);

  return (
    <div style={{width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
      <Spinner />
    </div>
  );
}
