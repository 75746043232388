// Helper function to convert dataURL to File object
export const dataURLtoFile = async (dataUrl: string, filename: string): Promise<File> => {
  const arr = dataUrl.split(',');
  const mime = arr[0].match(/:(.*?);/)?.[1] || 'image/jpeg';
  const bstr = atob(arr[1]);
  const u8arr = new Uint8Array(bstr.length);

  for (let i = 0; i < bstr.length; i++) {
    u8arr[i] = bstr.charCodeAt(i);
  }

  // Ensure filename has an extension
  const extension = mime.split('/')[1];
  const filenameWithExt = filename.includes('.') ? filename : `${filename}.${extension}`;

  return new File([u8arr], filenameWithExt, {type: mime});
};

// Helper function to convert URL to File object
export const urlToFile = async (url: string): Promise<File> => {
  if (url.startsWith('data:')) {
    // Generate a unique filename with timestamp
    const timestamp = new Date().getTime();
    return await dataURLtoFile(url, `avatar_${timestamp}`);
  }
  const response = await fetch(url);
  const blob = await response.blob();
  // Ensure we have a valid filename with extension
  const filename = url.split('/').pop() || 'image.jpg';
  const extension = blob.type.split('/')[1];
  const filenameWithExt = filename.includes('.') ? filename : `${filename}.${extension}`;

  return new File([blob], filenameWithExt, {type: blob.type});
};

export const urlToDataUrl = async (url: string): Promise<string> => {
  if (url.startsWith('data:')) {
    return url;
  }

  // Fetch the image from the provided URL
  const response = await fetch(url);
  const blob = await response.blob();

  // Convert the blob to a data URL using FileReader
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(blob);
  });
};
