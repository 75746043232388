import {ReactNode, memo} from 'react';
import styles from './ConnectsComponents.module.scss';

type ButtonTrayProps = {
  children?: ReactNode;
};

export const ButtonTray = memo(({children}: ButtonTrayProps) => {
  return <div className={styles.buttonTray}>{children}</div>;
});
ButtonTray.displayName = 'ButtonTray';
