import axios, {AxiosResponse} from 'axios';
import {resolve} from '../utils/utils';
import {AthleteRankedLeaderboardEntry, LeaderboardRankings} from 'api/Leaderboards/leaderboard.types';
import {TrackingParams} from '../redux/slices/fkoFormData';
import {Visit} from './Booking/useGetMboVisits';
import {EnrollmentClass} from './Classes/useGetPrograms';
import {JerseyInfo, Profile, SanitizedProfile} from 'user/player-info.interface';
import {ApiUser, Team} from 'user/user.interface';
import {Club} from 'api/Director/club.interface';
import {GetPlayerLastSessionSummaryParams} from './Trainer/useGetPlayerLastSessionSummary';
import {Assessment} from './Coach/useGetCoachInfo';
import {GetPlayerSessionParams} from './User/useGetPlayerSession';
import {roleType} from 'constants/constants';
import {AddCoachFormData} from 'pages/Director/AddCoach/AddCoach';
import {ErrorDetails} from '../common/error-details.interface';
import {getCurrentAccountId} from '../user/user-utils';
import {Studio, TrainerStudioSelectionType} from './Trainer/useStudioSelection';
import {PlayerShort} from 'pages/Trainer/Trainer';
import {GetPlayerAppointmentsParams} from './MLSChallenge/useGetPlayerAppointments';

export const user = '/user' as const;
export const player = '/player' as const;
export const misc = '/misc' as const;
export const trainer = '/trainer' as const;
export const coach = '/coach' as const;
export const director = '/director' as const;
export const mbo = '/mbo' as const;
export const hubspotFormsAPI = 'https://forms.hubspot.com/uploads/form/v2' as const;

// TODO not sure the _pending property actually exists...
export const getCurrentUser = async (): Promise<AxiosResponse<ApiUser & {_pending?: any}>> => {
  return axios.get(`${user}/user`);
};

export type UserExistsResponse = {
  email: string;
  userId?: string;
  profiles?: SanitizedProfile[];
};

export type UserAccountProfileExistResponse = {
  accountExists: boolean;
  profileExists: boolean;
};

// TODO Should accept sessionTypeNames (for appointments) or classNames/classIds (for classes).
//      Since we allow sessionTypeNames to be passed in via URL params, we only know them client-side and cannot
//      hardcode/determine them server-side based on the sport.
export const getUserExistsAndEligibility = async ({
  email,
  siteId,
  sessionTypeNames,
}: {
  email: string;
  siteId?: string;
  sessionTypeNames?: string[];
}): Promise<AxiosResponse<UserExistsResponse>> => {
  const params = [`email=${email}`];
  if (siteId) {
    params.push(`siteId=${siteId}`);
  }
  if (sessionTypeNames?.length && Array.isArray(sessionTypeNames)) {
    for (let i = 0; i < sessionTypeNames.length; i++) {
      const sessionTypeName = sessionTypeNames[i];
      params.push(`sessionTypeNames[${i}]=${sessionTypeName}`);
    }
  }
  return axios.get(`${mbo}/check-user?${params.join('&')}`);
};

export const getUserAccountProfileExistStatus = async ({
  email,
  phoneNumber,
}: {
  email?: string;
  phoneNumber?: string;
}): Promise<AxiosResponse<UserAccountProfileExistResponse>> => {
  const params = [];
  if (phoneNumber) {
    params.push(`phoneNumber=${encodeURIComponent(phoneNumber)}`);
  }
  if (email) {
    params.push(`email=${encodeURIComponent(email)}`);
  }
  return axios.get(`${user}/user-status?${params.join('&')}`);
};

export const getFreeTrialClassProfileAndEligibilityInfo = async ({
  email,
  classNames = [],
  programIds = [],
}: {
  email: string;
  classNames?: string[];
  programIds?: string[];
}): Promise<AxiosResponse<UserExistsResponse>> => {
  const params = [`email=${email}`];
  if (classNames?.length && Array.isArray(classNames)) {
    for (let i = 0; i < classNames.length; i++) {
      const className = classNames[i];
      params.push(`classNames[${i}]=${className}`);
    }
  }
  if (programIds?.length && Array.isArray(programIds)) {
    for (let i = 0; i < programIds.length; i++) {
      const programId = programIds[i];
      params.push(`programIds[${i}]=${programId}`);
    }
  }

  return axios.get(`${mbo}/ftc/check-user?${params.join('&')}`);
};

export const getClientStatus = async () => {
  return axios.get(`${mbo}/client-status`);
};

export const getPlayerDetails = async () => {
  return axios.get(`/player/details`);
};

export const getPlayerLeaderboards = async (): Promise<AxiosResponse<AthleteRankedLeaderboardEntry[]>> => {
  return axios.get(`${player}/ranked-leaderboards`);
};

export const getPublicLeaderboard = async (leaderboardId: string): Promise<AxiosResponse<LeaderboardRankings>> => {
  return axios.get(`misc/leaderboard/${leaderboardId}/rankings`);
};

/**
 * Gets all players for the current trainer's MBO site.
 * Current user must have a role of "trainer".
 */
export const getTrainerPlayers = async () => {
  return await axios.get<PlayerShort[]>(`${trainer}/players`).then((res) => res.data);
};

/**
 * Gets a single user/profile by profile ID.
 * Currently authenticated user must have a role of "trainer" and the requested profile must have the same MBO site as the trainer.
 */
export const getTrainerUser = async ({profileId, mboClientId}: {profileId?: string; mboClientId?: string}) => {
  const query = profileId ? `profileId=${profileId}` : `mboClientId=${mboClientId}`;
  return await resolve(axios.get(`${trainer}/user?${query}`).then((res) => res.data));
};

/**
 * Gets athlete session statistics for Toca Connects.
 * Currently authenticated user must have a role of "trainer" and the requested profile must have the same MBO site as the trainer.
 */
export type GetAthleteInfoParams = {clientId?: string | null; siteId?: string | null};
export const getAthleteInfo = async ({clientId, siteId}: GetAthleteInfoParams) => {
  return !siteId
    ? Promise.reject(new Error('Invalid parameters'))
    : axios.get(`${trainer}/connects/athlete-info?mboClientId=${clientId}&mboSiteId=${siteId}`).then((res) => res.data);
};

/**
 * Gets the last session summary for a player.
 */
export const getPlayerLastSessionSummary = async ({clientId, siteId}: GetPlayerLastSessionSummaryParams) => {
  return !siteId || !clientId
    ? Promise.reject(new Error('Invalid Parameters'))
    : axios
        .get(`${trainer}/connects/player-last-session?clientId=${clientId}&siteId=${siteId}`)
        .then((res) => res.data);
};

// ATTEMPT TO SET FIND AND SET MBO DATA FOR CURRENT USER
export const findAndSetMboDataForCurrentUser = async () => {
  return await resolve(axios.get(`${mbo}/updateAccountProfilesWithMboData`).then((res) => res.data));
};

// ANALYZE USER SETUP AND DATA
export const analyzeUser = async (data: any) => {
  const params: string[] = [];
  ['email', 'firstName', 'lastName'].forEach((param) => {
    if (data[param]) {
      params.push(`${param}=${encodeURIComponent(data[param])}`);
    }
  });

  return await resolve(axios.get(`${user}/admin/analyze-user?${params.join('&')}`).then((res) => res.data));
};

export interface UserUpdateInfo {
  tracking?: {
    fko?: unknown;
    guidedTour?: {
      completedDate: string;
    };
  };
}

/**
 * Updates the current user's info.
 */
export const updateUserInfo = async (userInfo: UserUpdateInfo) => {
  return axios.put(`${user}/user`, userInfo).then((res) => res.data);
};

export interface InitialProfileCreateData {
  email: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  dob?: string;
  gender?: string;
  levelOfPlay?: string;
  preferences?: {
    receiveTocaNotification?: boolean;
    receiveTocaNotificationOptInOn?: string;
  };
  tracking?: {
    acceptedTermsOn?: string;
    referrer?: string;
  };
}

export interface ProfileUpdate {
  email: string;
  profileId?: string;
  siteId?: string;
  update: {
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    dob?: string;
    club?: string;
    preferences?: {
      receiveTocaNotification?: boolean;
      receiveTocaNotificationOptInOn?: string;
      booking?: {
        day?: 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat' | 'Sun';
        startTime?: string; // 00:00:00
        endTime?: string; // 00:00:00
        weeks?: number;
        coach?: number;
        referenceStartDate?: string; // 2023-01-20T00:50:00
        sessionType?: {
          id: number;
          name: string;
        };
      };
    };
    playerCardInfo?: {
      patternIndex?: number;
      playerCardLightColor?: string;
      playerCardDarkColor?: string;
      playerCardTextColor?: string;
      imageSrc?: string;
    };
    jerseyInfo?: {
      jerseyName?: string;
      jerseyNumber?: number;
      jerseyLightColor?: string;
      jerseyDarkColor?: string;
      jerseyTextColor?: string;
    };
    tracking?: {
      acceptedTermsOn?: string;
      referrer?: string;
      fko?: any;
    };
  };
}

/**
 * Updates a profile (player) on the current user.
 */
export const createInitialProfile = async (data: InitialProfileCreateData) => {
  return axios.post(`${user}/initial-profile`, data).then((res) => res.data);
};

/**
 * Updates a profile (player) on the current user.
 */
export const updateProfileApi = async (profileUpdate: ProfileUpdate) => {
  return axios.put(`${user}/profile`, profileUpdate).then((res) => res.data);
};

export const updateUserAccount = async (accountUpdate: {phoneNumber: string}) => {
  return axios.put(`${user}/account`, accountUpdate).then((res) => res.data);
};

export interface OnboardingProfileUpdate {
  token: string;
  update: {
    firstName: string;
    lastName: string;
  };
}

export const updateOnboardingProfileAPI = async (data: OnboardingProfileUpdate) => {
  return axios.put(`${user}/onboarding/profile`, data).then((res) => res.data);
};

/**
 * Updates a profile without authentication.
 */
export const updateProfileFkoApi = async (profileUpdate: ProfileUpdate): Promise<SanitizedProfile> => {
  return axios.put(`${user}/fko/profile`, profileUpdate).then((res) => res.data);
};

export interface NewMboClientData {
  // ID that is returned with the API result to allow mapping of newly created MBO clients to client-side state
  clientSideOnlyId?: string;
  // Indicates whether the client is the account holder, i.e. the main MBO client
  accountHolder?: boolean;
  firstName: string;
  lastName: string;
  birthdate?: string;
  referredBy?: string;
  experience?: string;
  club?: string;
}

export interface CreateMboClientsResult {
  results: Array<{
    clientSideOnlyId: string;
    mbo?: {
      clientId: string;
      uniqueId: string;
      siteId: string;
    };
    error?: string;
  }>;
  clients?: any[]; // MBO client data
  profiles?: SanitizedProfile[];
}

type CreateMboClientsParams = {
  siteId: string;
  clients: NewMboClientData[];
  email: string;
  isFko?: boolean;
  returnProfiles?: boolean;
};
/**
 * Creates new MBO clients for the current user.
 */
export const createMboClients = async ({
  siteId,
  clients,
  email,
  isFko = false,
  returnProfiles = false,
}: CreateMboClientsParams): Promise<CreateMboClientsResult> => {
  const data = {
    siteId: siteId,
    clients: clients,
    email: email,
    returnProfiles: returnProfiles,
  };

  return isFko
    ? axios.post(`${mbo}/fko/clients`, data).then((res) => res.data)
    : axios.post(`${mbo}/clients`, data).then((res) => res.data);
};

interface AppointmentData {
  ClientId: string;
  LocationId: number;
  SessionTypeId: number;
  StaffId: number;
  StaffRequested?: boolean;
  StartDateTime: string;
  GenderPreference?: string;
  SendEmail?: boolean;
  Notes?: string;
  Test?: boolean;
}

/**
 * Creates a new appointment (FKO or normal) in MBO.
 */
export const createAppointment = async (
  siteId: string,
  appointmentData: AppointmentData,
  options?: {fko?: boolean}
) => {
  const url = options?.fko ? `${mbo}/fko/appointment` : `${mbo}/appointment`;
  const data = {
    siteId: siteId,
    appointment: appointmentData,
  };
  return axios.post(url, data).then((res) => res.data);
};

/**
 * Get nearest Toca centers based on user location.
 */
export const getNearestLocations = async (lat: number, lon: number) => {
  return axios.get(`${misc}/nearestLocations?lat=${lat}&lon=${lon}`).then((res) => res.data);
};

/**
 * Verifies a recaptcha token.
 */
export const verifyRecaptcha = async (token: string) => {
  return axios.post(`${misc}/recaptcha/verify`, {token: token}, {withCredentials: false}).then((res) => res.data);
};

// Note: HubSpot properties must be lowercase and match properties set up in HubSpot.
type HubSpotContactInfo = TrackingParams & {
  email?: string;
  firstname?: string;
  lastname?: string;
  phone?: string;
  fko_method?: string;
  locations__drop_down_?: string;
  mytoca_site?: string;
  fko_request_date?: string;
};

/**
 * Creates or updates a HubSpot contact.
 */
export const createOrUpdateHubSpotContact = async (data: HubSpotContactInfo) => {
  return axios.post(`${mbo}/hubspot/contact`, data).then((res) => res.data);
};

type HubSpotFormData = Record<string, string | number | boolean | undefined>;

export const submitHubSpotForm = async (formId: string, portalId: string, data: HubSpotFormData) => {
  const url = `${hubspotFormsAPI}/${portalId}/${formId}`;

  // Create a new axios instance for this specific request to avoid the default interceptors
  const hubspotAxios = axios.create();

  try {
    const response = await hubspotAxios.post(url, data, {
      headers: {'Content-Type': 'multipart/form-data'},
      withCredentials: false,
    });
    return response.data;
  } catch (error) {
    console.error('Error submitting HubSpot form:', error);
    throw error;
  }
};

// Note: HubSpot properties must be lowercase and match properties set up in HubSpot.
interface HubSpotDealInfo {
  id?: number;
  dealname: string;
  multi_appointment_booking_info: string;
  mbo_site_id: string;
  program_name?: string;
}

/**
 * Creates a HubSpot deal.
 */
export const createOrUpdateHubSpotDeal = async (data: HubSpotDealInfo) => {
  return axios.post(`${mbo}/hubspot/deal`, data).then((res) => res.data);
};

/**
 * Gets ALL past and future visits of an MBO client.
 */
export type GetAllVisitsParams = {
  clientId?: string | null;
  siteId?: string | null;
};
export const getAllVisits = async ({clientId, siteId}: GetAllVisitsParams): Promise<Visit[]> => {
  return !clientId || !siteId
    ? Promise.reject(new Error('Invalid parameters'))
    : axios.get(`${mbo}/all-visits?clientId=${clientId}&siteId=${siteId}`).then((res) => res.data);
};

/**
 * Cancels a future MBO appointment.
 */
export const cancelAppointment = async (appointmentId: number, clientId?: string, siteId?: string) => {
  return !clientId || !siteId
    ? Promise.reject(new Error('Invalid parameters'))
    : axios.post(`${mbo}/appointment/cancel`, {appointmentId, clientId, siteId}).then((res) => res.data);
};

/**
 * Prefills a waiver template for the current player and returns the prefilled waiver info.
 */
export const prefillWaiver = async (templateId: string, prefillData?: {expiration?: number}) => {
  return axios.post(`${player}/waiver/prefill`, {templateId, prefillData}).then((res) => res.data);
};

/**
 * Validates a signed waiver based on waiver template and prefill ID.
 */
export const validateSignedWaiver = async (templateId: string, prefillId: string) => {
  return axios.get(`${player}/waiver/validate?templateId=${templateId}&prefillId=${prefillId}`).then((res) => res.data);
};

// //
// MBO Class/Program bookings
// //
export type GetMboProgramsParams = {siteId?: string | null};
export const getMboPrograms = async ({siteId}: GetMboProgramsParams) => {
  return !siteId
    ? Promise.reject(new Error('Invalid siteId'))
    : axios.get(`${mbo}/programs?siteId=${siteId}`).then((res) => res.data);
};

// ---Add Client to Enrollment---
export type RegisterToEnrollmentParams = {
  siteId: string;
  clientId: string;
  enrollmentId: number;
  enrollFromDate?: string;
  enrollOpenDates?: string[];
  test?: boolean;
  sendEmail?: boolean;
  waitlist?: boolean;
};
export type ProgramsCheckoutResponse = {
  // TODO improve this type
  Classes?: EnrollmentClass[];
  Clients?: {
    Action?: string;
    FirstName?: string;
    LastName?: string;
    Id?: string;
  }[];
  error?: {
    message?: string;
    playerId?: string;
  };
};
export const registerToEnrollment = async (data: RegisterToEnrollmentParams) => {
  return axios.post<ProgramsCheckoutResponse>(`${mbo}/programs`, data);
};

// ---Add Client to Class---
export type AddClientToClassParams = {
  siteId: string;
  clientId: string;
  classId: number;
  sendEmail?: boolean;
  test?: boolean;
  waitlist?: boolean;
  freeTrial?: boolean;
};
export type AddClientToClassResponse = {
  // TODO CLASSES confirm error handling
  Visit?: {
    Action: string;
    AppointmentGenderPreference: string;
    AppointmentId: number;
    AppointmentStatus: string;
    ClassId: number;
    ClientId: string;
    CrossRegionalBookingPerformed: false;
    EndDateTime: string;
    Id: number;
    LastModifiedDateTime: string;
    LateCancelled: boolean;
    LocationId: number | null;
    MakeUp: boolean;
    Name: string | null;
    ProductId: unknown | null;
    ServiceId: unknown | null;
    ServiceName: unknown | null;
    SignedIn: boolean;
    SiteId: unknown | null;
    StaffId: number | null;
    StartDateTime: string;
    WaitlistEntryId: unknown | null;
    WebSignup: boolean;
  };
  error?: {
    message?: string;
    playerId?: string;
  };
};
export const addClientToClass = async (data: AddClientToClassParams) => {
  return axios.post<AddClientToClassResponse>(`${mbo}/classes`, data);
};

export const triggerBackendResync = async ({resyncId}: {resyncId: string}) => {
  return axios.post(`${mbo}/available-sessions/resync`, {resyncId});
};

export type SetSessionSelectionRequestPayload = {
  clientSessionSummaryId: string;
  selectedExercisesIndex: number[];
  trainerId: string;
};

export const setSessionSelection = async (requestPayload: SetSessionSelectionRequestPayload) => {
  const response = await axios.put(`${trainer}/connects/athlete-info/session-selection`, requestPayload);
  return response.data;
};

export const getCoachInfo = async (coachId: string) => {
  return axios.get(`${coach}/details?coachId=${coachId}`).then((res) => res.data);
};

const getRoleBasedRoute = (role: string) => (role === roleType.director ? director : coach);

export const getAssessmentPlayers = async (assessmentId: string, yearOfBirth: string, sortBy: string, role: string) => {
  const roleBasedRoute = getRoleBasedRoute(role);
  const urlParams = [];

  if (assessmentId) {
    urlParams.push(`assessmentId=${assessmentId}`);
  }
  if (yearOfBirth) {
    urlParams.push(`yearOfBirth=${yearOfBirth}`);
  }
  if (sortBy) {
    urlParams.push(`sortBy=${sortBy}`);
  }

  return axios.get(`${roleBasedRoute}/assessment/players?${urlParams.join('&')}`).then((res) => res.data);
};

export const updateTeam = async (requestPayload: Partial<Team>, role: string) => {
  const roleBasedRoute = getRoleBasedRoute(role);
  const response = await axios.put(`${roleBasedRoute}/team`, requestPayload);
  return response.data;
};

export const getAssessmentById = async (assessmentId: string, role: string) => {
  const roleBasedRoute = getRoleBasedRoute(role);
  return axios.get(`${roleBasedRoute}/assessment?id=${assessmentId}`).then((res) => res.data);
};

export const getSelectedPlayersAssessmentPerformanceData = async (
  playerIds: string,
  assessmentId: string,
  role: string,
  sortBy = ''
) => {
  const roleBasedRoute = getRoleBasedRoute(role);
  return !playerIds || !assessmentId
    ? Promise.reject(new Error('Invalid Parameters'))
    : axios
        .get(
          `${roleBasedRoute}/assessment/players/performance?playerIds=${playerIds}&assessmentId=${assessmentId}&sortBy=${sortBy}`
        )
        .then((res) => res.data);
};

export const getAssessmentStatsById = async (assessmentId: string, role: string) => {
  const roleBasedRoute = getRoleBasedRoute(role);
  return axios.get(`${roleBasedRoute}/assessment/stats?assessmentId=${assessmentId}`).then((res) => res.data);
};

export const deletePlayerFromAssessment = async (assessmentId: string, playerId: string): Promise<Assessment> => {
  return axios
    .get(`${coach}/assessment/player?assessmentId=${assessmentId}&playerId=${playerId}`)
    .then((res) => res.data);
};

export const getTeamPublicInfo = async ({teamId}: {teamId?: string}) => {
  return !teamId
    ? Promise.reject(new Error('Invalid Parameters'))
    : axios.get(`${user}/team/public?teamId=${teamId}`).then((res) => res.data);
};

/**
 * Gets the session for a player by clientId, siteId and sessionId.
 */
export const getPlayerSession = async ({clientId, siteId, sessionId}: GetPlayerSessionParams) => {
  return !siteId || !clientId || !sessionId
    ? Promise.reject(new Error('Invalid Parameters'))
    : axios
        .get(`${player}/player-session?clientId=${clientId}&siteId=${siteId}&sessionId=${sessionId}`)
        .then((res) => res.data);
};

export const addPlayersToTeam = async ({
  teamId,
  clientIds,
  siteId,
}: {
  teamId: string;
  clientIds: string[];
  siteId: string;
}) => {
  return !teamId || !clientIds.length
    ? Promise.reject(new Error('Invalid Parameters'))
    : axios.put(`${user}/team/add-players`, {teamId, clientIds, siteId}).then((res) => res.data);
};

export const getDirectorInfo = async (directorId: string) => {
  return axios.get(`${director}/details?directorId=${directorId}`).then((res) => res.data);
};

export const getTeamCoaches = async (teamId: string) => {
  return axios.get(`${director}/team/coaches?teamId=${teamId}`).then((res) => res.data);
};

export async function createAccountAndProfile(data: {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}): Promise<{accountId: string; profile: Profile}> {
  return axios.post(`${director}/create-account-and-profile`, data).then((res) => res.data);
}

export async function getProfileByEmail(email: string): Promise<Profile> {
  return axios.get(`${director}/profile?email=${email}`).then((res) => res.data);
}

export async function addCoachToTeam({
  newCoachData,
  teamId,
}: {
  newCoachData: Partial<AddCoachFormData>;
  teamId: string;
}): Promise<{team: Team; coach: Profile}> {
  const response = await axios.post(`${director}/add-coach`, {...newCoachData, teamId});
  return response.data;
}

export async function assignExistingCoachToTeam({
  coachData,
  teamId,
}: {
  coachData: Pick<AddCoachFormData, 'email' | 'coachType'>;
  teamId: string;
}): Promise<{team: Team; coach: Profile}> {
  const response = await axios.post(`${director}/assign-coach`, {...coachData, teamId});
  return response.data;
}

export async function inviteUserToTeam({
  email,
  teamId,
  siteId,
  role,
  profileData,
}: {
  email: string;
  teamId: string;
  siteId: string;
  role: string[];
  profileData?: any;
}): Promise<any> {
  const response = await axios.post(`${director}/invite`, {email, teamId, siteId, role, profileData});
  return response.data;
}

export async function deleteCoachFromTeam({
  teamId,
  coachId,
  coachType,
}: {
  teamId: string;
  coachId: string;
  coachType: string;
}): Promise<Team> {
  const response = await axios.delete(
    `${director}/delete-coach?teamId=${teamId}&coachId=${coachId}&coachType=${coachType}`
  );
  return response.data;
}

export async function saveErrorDetails(details: ErrorDetails): Promise<void> {
  details.accountId ||= getCurrentAccountId();
  details.url ||= window.location.href;
  axios.post(`${misc}/error-details`, details).catch((err) => {
    console.error('Failed to save error details', err);
  });
}

export async function addTeamToClub(team: Partial<Team>, clubId: string): Promise<any> {
  const response = await axios.post(`${director}/club/team`, {...team, clubId});
  return response.data;
}

export async function createAssessment(assessment: Partial<Assessment>): Promise<any> {
  const response = await axios.post(`${director}/assessment`, assessment);
  return response.data;
}

export async function updateAssessment(assessment: Partial<Assessment>): Promise<any> {
  const response = await axios.put(`${director}/assessment`, assessment);
  return response.data;
}

export async function updateClub(club: Partial<Club>, role: string): Promise<any> {
  const roleBasedRoute = getRoleBasedRoute(role);
  const response = await axios.put(`${roleBasedRoute}/club`, club);
  return response.data;
}

export async function updateClubLogo(formData: FormData, role: string): Promise<any> {
  const roleBasedRoute = getRoleBasedRoute(role);
  const response = await axios.put(`${roleBasedRoute}/club/update-logo`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
}

export async function validateTokenAndGetUserInfo(token: string): Promise<any> {
  const response = await axios.get(`${user}/onboarding/validate?token=${token}`);
  return response.data;
}

export async function validatePlayerTokenAndGetPlayerInfo(token: string): Promise<any> {
  const response = await axios.get(`${player}/onboarding/validate?token=${token}`);
  return response.data;
}
export async function linkPlayerToAccount(): Promise<any> {
  const response = await axios.post(`${player}/link-account-profile`);
  return response.data;
}

export async function getClubPublicInfo({clubId}: {clubId?: string}): Promise<any> {
  return axios.get(`${user}/club/public?clubId=${clubId}`).then((res) => res.data);
}

export type StudioSelectionParams = {
  // date?: string;
  siteId: string;
  trainerStaffId: string;
};
/**
 * Retrieves a all available studios for the current day.
 */
export const getTrainerAvailableStudios = async ({
  siteId,
  trainerStaffId,
}: StudioSelectionParams): Promise<{availableStudios: Studio[]; error?: string}> => {
  return !trainerStaffId || !siteId
    ? Promise.reject(new Error('Invalid parameters'))
    : axios.get(`${trainer}/available-studios?siteId=${siteId}&staffId=${trainerStaffId}`).then((res) => res.data);
};

/**
 * Retrieves a trainer's studio selection for the current day.
 */
export const getStudioSelection = async ({
  siteId,
  trainerStaffId,
}: StudioSelectionParams): Promise<{
  studioSelection?: TrainerStudioSelectionType | null;
  referenceDate?: string;
  error?: string;
}> => {
  return !trainerStaffId || !siteId
    ? Promise.reject(new Error('Invalid parameters'))
    : axios.get(`${trainer}/studio-selection?siteId=${siteId}&staffId=${trainerStaffId}`).then((res) => res.data);
};

/**
 * Allows a trainer to select a studio for all of their sessions for the current day.
 */
export type SetStudioSelectionParams = {
  profileId: string;
  trainerStaffId: string;
  studioId: string;
  siteId: string;
  // selectionDate?: string;
  // startDateTime?: string;
  // endDateTime?: string;
};
export const setStudioSelection = async ({
  profileId,
  trainerStaffId,
  studioId,
  siteId,
}: // selectionDate,
SetStudioSelectionParams): Promise<{
  success: boolean;
  studioSelection?: TrainerStudioSelectionType | null;
  error?: string;
}> => {
  return !profileId || !trainerStaffId || !studioId || !siteId
    ? Promise.reject(new Error('Invalid parameters'))
    : axios
        .post(`${trainer}/studio-selection`, {
          profileId,
          staffId: trainerStaffId,
          studioId,
          siteId,
          // selectionDate,
        })
        .then((res) => res.data);
};

export const updateJerseyInfo = async (jerseyInfo: JerseyInfo) => {
  const response = await axios.put(`${player}/player-jersey/info`, jerseyInfo);
  return response.data;
};

export const getPlayerAppointments = async ({
  includeSessionSummary = true,
  includePastAppointments = true,
  includeFutureAppointments = true,
  pastAppointmentsOrder = 'desc',
  futureAppointmentsOrder = 'asc',
  pastAppointmentsLimit = 50,
  pastAppointmentsSkip,
  futureAppointmentsLimit,
  futureAppointmentsSkip,
}: GetPlayerAppointmentsParams) => {
  // Utility function to filter undefined parameters
  const filterParams = (params: Record<string, any>) =>
    Object.fromEntries(Object.entries(params).filter(([_, v]) => v !== undefined));

  const params = filterParams({
    includeSessionSummary,
    includePastAppointments,
    includeFutureAppointments,
    pastAppointmentsLimit,
    pastAppointmentsSkip,
    futureAppointmentsLimit,
    futureAppointmentsSkip,
    pastAppointmentsOrder,
    futureAppointmentsOrder,
  });

  const response = await axios.get(`${player}/appointments`, {
    params,
  });
  return response.data;
};
