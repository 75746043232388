import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import styles from './CreatePlayerForm.module.scss';
import {FormInput} from '../../components/Forms/FormInput/FormInput';
import {FormButton} from '../../components/Forms/FormButton/FormButton';
import {addFkoPlayer, FkoPlayer} from '../../redux/slices/fkoFormData';
import uuid from 'react-uuid';
import moment from 'moment';
import {useEffect, useMemo, useRef, useState} from 'react';
import {FormCheckbox} from '../../components/Forms/FormCheckbox/FormCheckbox';
import {useAppDispatch, useAppSelector} from '../../redux/reduxUtils';
import {useGetSport} from 'common/useGetSport';

export type CreatePlayerFormType = FkoPlayer;

export type CreatePlayerFormProps = {
  additionalOnSubmit?: (player?: CreatePlayerFormType) => void;
  defaultValues?: Partial<CreatePlayerFormType>;
  errorMessage?: string;
  showAccountHolderCheckbox?: boolean;
  siteId: string;
  submitButtonText?: string;
};

export const CreatePlayerForm = ({
  additionalOnSubmit,
  defaultValues,
  errorMessage,
  showAccountHolderCheckbox,
  siteId,
  submitButtonText,
}: CreatePlayerFormProps) => {
  const dispatch = useAppDispatch();
  const {userData, playersArray, siteId: fkoSiteId} = useAppSelector((state) => state.fkoFormDataReducer);
  const {isDbat} = useGetSport();

  const accountHolderDisplayName = `${userData?.firstName} ${userData?.lastName}`;
  const date = defaultValues?.dob ? moment(new Date(defaultValues.dob)).format('YYYY-MM-DD') : undefined;
  const playerIsLocked =
    !!defaultValues?.accountHolder || !!defaultValues?._id || !!defaultValues?.mboDetails?.clientId;
  const playerCanBeAccountHolder =
    showAccountHolderCheckbox &&
    userData?.location === siteId &&
    !playersArray.find((p) => p.accountHolder) &&
    !playersArray.find(
      (p) =>
        p.firstName.toLowerCase() === userData?.firstName.toLowerCase() &&
        p.lastName.toLowerCase() === userData?.lastName.toLowerCase()
    );

  const [showDateInput, setShowDateInput] = useState(Boolean(date));
  const [autoFocusDateInput, setAutoFocusDateInput] = useState(!showDateInput);
  const [playerIsAccountHolder, setPlayerIsAccountHolder] = useState(
    defaultValues?.accountHolder ||
      !!(defaultValues?.playerId && !playersArray.filter((p) => p.playerId !== defaultValues?.playerId).length)
  );

  // TODO Don't show option to use account holder as player if the account holder profile isn't associated with the
  //      selected site. In that case, we should hide the checkbox, prefill the first name and last name from the
  //      initial form, collect the DOB, and then add the player.

  const onSubmit = (player: CreatePlayerFormType) => {
    player.accountHolder = playerIsAccountHolder;
    if (player.dob) {
      player.dob = moment(new Date(player.dob)).format('YYYY-MM-DD');
    }
    dispatch(addFkoPlayer(player));
    additionalOnSubmit?.(player);
  };

  const showDateInputAndFocus = () => {
    setAutoFocusDateInput(true);
    setShowDateInput(true);
  };

  const restrictedNamePairs = useRef<string[][]>();
  useEffect(() => {
    restrictedNamePairs.current = [
      ...playersArray.map((player) =>
        // if we're editing a player, don't restrict their own name
        (defaultValues?.playerId ? defaultValues.playerId !== player.playerId : true) &&
        // prevent matching both first & last with existing profiles (unless mboClient siteId is different)
        (!player.mboDetails || player.mboDetails?.siteId === siteId)
          ? [player.firstName.toLowerCase(), player.lastName.toLowerCase()]
          : []
      ),
      // prevent matching RegistrationLandingForm user name, unless playerIsAccountHolder checkbox checked
      !playerIsAccountHolder && userData?.firstName && userData?.lastName
        ? [userData.firstName.toLowerCase(), userData.lastName.toLowerCase()]
        : [],
    ];
  }, [playerIsAccountHolder, userData]);

  const validationSchema = yup.object().shape({
    playerId: yup.string(),
    isEligible: yup.boolean(),
    isDbat: yup.boolean(),
    firstName: yup
      .string()
      .trim('Name cannot include leading or trailing spaces')
      .test(
        'repeatname',
        'Sorry, player name must be different than other names on account',
        (firstName, testContext) => {
          const {lastName} = testContext.parent;
          if (!firstName || !lastName) {
            return true;
          }
          return !restrictedNamePairs.current?.find(
            (namePairs) => namePairs[0] === firstName.toLowerCase() && namePairs[1] === lastName.toLowerCase()
          );
        }
      )
      .required('Required'),
    lastName: yup.string().trim('Name cannot include leading or trailing spaces').required('Required'),
    dob: yup.date().typeError('Required').required('Required'),
  });

  // initializes hidden values when required
  const adjustedDefaults: Partial<CreatePlayerFormType> = useMemo(() => {
    const values = {
      ...defaultValues,
      eligibility: {
        isEligible: true,
      },
      dob: date,
      isDbat,
    };
    if (!values?.playerId) {
      values.playerId = uuid();
    }
    if (playerCanBeAccountHolder) {
      if (playerIsAccountHolder) {
        Object.assign(values, {
          firstName: userData.firstName,
          lastName: userData.lastName,
          dob: undefined,
        });
      } else if (!playerIsAccountHolder) {
        Object.assign(values, {
          firstName: '',
          lastName: '',
          dob: undefined,
        });
      }
    }
    return values;
  }, [date, defaultValues, isDbat, playerIsAccountHolder, playerCanBeAccountHolder]);

  const {
    register,
    handleSubmit,
    reset,
    formState: {errors},
  } = useForm<CreatePlayerFormType>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: adjustedDefaults,
  });

  // defaultValues will change based on AccountHolderIsPlayer checkbox
  // This is needed because input values aren't inherently cleared when defaultvalues change
  useEffect(() => {
    reset(adjustedDefaults);
  }, [adjustedDefaults, reset]);

  return (
    <>
      {playerCanBeAccountHolder && (
        <div className="flex flex-col gap-1">
          <div className={styles.checkboxWrapper}>
            <FormCheckbox
              id="userplayer"
              labelText={
                <p className={styles.checkboxText}>
                  Account holder <span className="capitalize">{accountHolderDisplayName}</span> is also the player
                </p>
              }
              defaultChecked={false}
              onChange={() => setPlayerIsAccountHolder((prev) => !prev)}
            />
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit(onSubmit)} className={styles.container}>
        <input type="hidden" {...register('playerId')} />
        <input type="hidden" {...register('eligibility.isEligible')} />
        <input type="hidden" {...register('isDbat')} />
        <div className={styles.formUpper}>
          <FormInput
            spellCheck="false"
            {...register('firstName')}
            type="text"
            id="firstName"
            placeholder={playerCanBeAccountHolder ? 'First Name - Player 1' : 'Player First Name'}
            disabled={playerIsAccountHolder || playerIsLocked}
            hasError={!!errors.firstName?.message}
          />
          <small className="err-message">{errors.firstName?.message ?? '\u00A0'}</small>

          <FormInput
            spellCheck="false"
            {...register('lastName')}
            type="text"
            id="lastName"
            placeholder={playerCanBeAccountHolder ? 'Last Name - Player 1' : 'Player Last Name'}
            disabled={playerIsAccountHolder || playerIsLocked}
            hasError={!!errors.lastName?.message}
          />
          <small className="err-message">{errors.lastName?.message ?? '\u00A0'}</small>

          {/* Number input placeholder and actual input */}
          {/* Date input order is controlled by client localization. don't put in placeholder. */}
          {!showDateInput && (
            <FormInput
              spellCheck="false"
              type="text"
              placeholder="Player Date of Birth"
              onFocus={showDateInputAndFocus}
            />
          )}
          {showDateInput && (
            <FormInput
              spellCheck="false"
              {...register('dob')}
              type="date"
              id="dob"
              placeholder="Player Date of Birth"
              autoFocus={autoFocusDateInput}
              hasError={!!errors.dob?.message}
              min="1900-01-01"
              max={new Date().toISOString().split('T')[0]}
            />
          )}
          <small className="err-message">{errors.dob?.message ?? '\u00A0'}</small>
        </div>

        <div className={styles.formLower}>
          <small className="err-message">{errorMessage ?? '\u00A0'}</small>

          <FormButton type="submit" isPrimary>
            {submitButtonText || 'ADD PLAYER'}
          </FormButton>
        </div>
      </form>
    </>
  );
};
