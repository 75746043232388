import {useState} from 'react';
import {ArrowButton} from 'components/Buttons/ArrowButton';
import {useNavigate} from 'react-router-dom';
import {signInWithEmailAndPassword} from 'firebase/auth';
import {auth as firebaseAuth} from '../../firebase/config';
import {setAccessToken, setCurrentAccountId} from 'user/user-utils';
import {logLoginEvent} from 'common/analytics-events';
import * as yup from 'yup';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';

export const MLSAuthEmailSignIn = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    email: yup.string().required('Email is required').email('Email is invalid'),
    password: yup
      .string()
      .required('Password is required')
      .min(6, 'Password must be at least 6 characters')
      .max(40, 'Password must not exceed 40 characters'),
  });

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const resetError = () => setError('');

  const handleEmailSignIn = async (data: any) => {
    setIsLoading(true);
    setError('');

    try {
      const userCredential = await signInWithEmailAndPassword(firebaseAuth, data.email, data.password);
      const authUser = userCredential.user;

      setAccessToken((await authUser.getIdTokenResult()).token);
      setCurrentAccountId(authUser.uid);

      logLoginEvent('emailAndPassword', {
        userUid: userCredential?.user?.uid,
        operationType: userCredential?.operationType,
      });

      navigate('/profiles');
    } catch (err: any) {
      if (err.code === 'auth/user-not-found' || err.code === 'auth/wrong-password') {
        setError('Invalid email or password');
      } else {
        setError('An error occurred. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-black">
      <div className="flex relative flex-col min-h-screen max-w-lg mx-auto flex-1 text-white items-center justify-center px-4">
        <ArrowButton
          className="absolute top-4 left-2"
          onClick={() => navigate('/auth/signin')}
          variant="secondary"
          arrowDirection="left"
        />

        <div className="flex flex-col items-center justify-center min-h-[calc(100vh-250px)] text-center">
          <h1 className="text-4xl md:text-5xl font-teko font-bold">SIGN IN WITH EMAIL</h1>
          <p className="text-base md:text-lg font-helvetica font-thin mt-1 mb-10">
            Enter your email and password to sign in
          </p>

          <form onSubmit={handleSubmit(handleEmailSignIn)} className="w-full max-w-md">
            <div className="mb-4">
              <label className="block text-sm opacity-30 text-left mb-1">Email</label>
              <input
                type="email"
                {...register('email', {
                  onChange: resetError,
                })}
                className="w-full p-3 text-base rounded bg-white text-black"
                placeholder="Enter your email"
              />
              {errors.email && (
                <p className="text-mls-error text-sm text-left mt-1">{errors.email.message as string}</p>
              )}
            </div>

            <div className="mb-4">
              <label className="block text-sm opacity-30 text-left mb-1">Password</label>
              <input
                type="password"
                {...register('password', {
                  onChange: resetError,
                })}
                className="w-full p-3 text-base rounded bg-white text-black"
                placeholder="Enter your password"
              />
              {errors.password && (
                <p className="text-mls-error text-sm text-left mt-1">{errors.password.message as string}</p>
              )}
            </div>

            {error && <p className="text-mls-error text-sm text-left mt-1 mb-4">{error}</p>}

            <ArrowButton className="mt-8 self-end float-right" type="submit" loading={isLoading} hasError={!!error} />
          </form>
        </div>
      </div>
    </div>
  );
};
