import {memo} from 'react';
import styles from './BaselineExerciseKey.module.scss';

export const BaselineExerciseKey = memo(() => {
  return (
    <div className={styles.container}>
      <p>(P) Practice - (C) Challenge - (DF) Dominant Foot - (NDF) Non Dominant Foot</p>
    </div>
  );
});
BaselineExerciseKey.displayName = 'BaselineExerciseKey';
