import {Outlet, useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import AuthContainer from '../components/AuthContainer/AuthContainer';
import TrainerAuthContainer from '../components/TrainerAuthContainer/TrainerAuthContainer';
import {Helmet} from 'react-helmet';
import {useProfiles} from '../api/User/useProfiles';
import {useEffect, useMemo} from 'react';
import {useTrainerContext} from 'context/UserContextProvider';
import {connectsIsEnabledForTrainer} from 'feature-flags/connects';

export default function RootTrainerLayout() {
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const trainerContext = useTrainerContext();

  const {currentProfile} = useProfiles();
  const [searchParams] = useSearchParams();
  const siteId = useMemo(
    () => searchParams.get('mboSiteId') ?? currentProfile.mboDetails?.siteId ?? 'void',
    [currentProfile.mboDetails?.siteId, searchParams]
  );

  useEffect(() => {
    if (trainerContext && (pathname.startsWith('/emulation') || pathname.startsWith('/trainer/connects'))) {
      const requestedUri = window.location.pathname + window.location.search;

      trainerContext.endEmulateUser();
      navigate(requestedUri, {replace: true});
    }

    if (!connectsIsEnabledForTrainer({siteId}) && pathname.startsWith('/trainer/connects')) {
      console.error(`Connects experience not enabled for this siteId: ${siteId}`);
      navigate('/trainer', {replace: true});
    }
  }, [navigate, pathname, siteId, trainerContext]);

  return (
    <>
      <Helmet>
        <title>MyTOCA | Trainer View</title>
      </Helmet>
      <AuthContainer>
        <TrainerAuthContainer>
          <Outlet />
        </TrainerAuthContainer>
      </AuthContainer>
    </>
  );
}
