import {useState} from 'react';
import {ArrowButton} from 'components/Buttons/ArrowButton';
import {useNavigate} from 'react-router-dom';
import {PhoneInput} from 'react-international-phone';
import 'react-international-phone/style.css';
import {getUserAccountProfileExistStatus} from 'api/api';
import {PhoneNumberUtil} from 'google-libphonenumber';
import {isDevelopmentEnvironment} from 'common/isTestEnvironment';

export const MLSAuthSignin = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const phoneUtil = PhoneNumberUtil.getInstance();

  const isValidPhoneNumber = (phoneNumber: string) => {
    // Skip validation in development environment to allow for firebase test numbers
    if (isDevelopmentEnvironment) {
      return true;
    }
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phoneNumber));
    } catch (error) {
      return false;
    }
  };

  const handleCheckPhoneNumber = async (phoneNumber: string) => {
    setIsLoading(true);
    try {
      if (!phoneNumber) {
        setError('Phone number is required');
        return;
      }
      if (!isValidPhoneNumber(phoneNumber)) {
        setError('Invalid phone number');
        return;
      }
      const formattedPhone = phoneNumber.replace(/[\s()-]/g, '');
      const response = await getUserAccountProfileExistStatus({
        phoneNumber: formattedPhone,
      });
      if (response.data.accountExists) {
        navigate('/auth/signin/phone', {state: {phoneNumber: formattedPhone}});
      } else {
        setError('Account with this phone number does not exist');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-black">
      <div className="flex relative flex-col min-h-screen max-w-lg mx-auto flex-1 text-white items-center justify-center px-4">
        <ArrowButton
          className="absolute top-4 left-2"
          onClick={() => navigate('/auth')}
          variant="secondary"
          arrowDirection="left"
        />

        <div className="flex flex-col items-center justify-center min-h-[calc(100vh-250px)] text-center bg-rsed-500">
          <h1 className="text-4xl md:text-5xl font-teko font-bold mb-2">WHAT IS YOUR PHONE NUMBER?</h1>
          <p className="font-helvetica font-thin text-base md:text-lg mb-8">
            Enter your phone number to confirm and verify your account
          </p>

          <div className="w-full relative max-w-md flex flex-col">
            <PhoneInput
              className="mt-4"
              defaultCountry="us"
              value={phoneNumber}
              onChange={(phone) => {
                setPhoneNumber(phone);
                setError('');
              }}
              countrySelectorStyleProps={{
                buttonStyle: {
                  borderRadius: '8px',
                  padding: '3px 6px',
                },
              }}
              inputStyle={{
                width: '100%',
                marginLeft: '12px',
                borderRadius: '8px',
                fontSize: '16px',
              }}
              disabled={isLoading}
            />
            {error && <p className="text-mls-error text-sm text-left mt-2">{error}</p>}
            <ArrowButton
              className="mt-12 self-end"
              onClick={() => handleCheckPhoneNumber(phoneNumber)}
              hasError={!!error}
              loading={isLoading}
            />
          </div>

          <div className="w-full fixed bottom-8 left-0 md:left-auto px-6 max-w-md">
            <div className="w-full flex items-center gap-4 mb-6">
              <div className="h-px flex-1 bg-white opacity-70" />
              <span className="text-gray-400 text-base">or</span>
              <div className="h-px flex-1 bg-white opacity-70" />
            </div>

            <button
              onClick={() => navigate('/auth/signin/email')}
              className="p-2 text-white text-base border border-white rounded-full w-full"
            >
              Sign in with email
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
