import {memo} from 'react';
import styles from './ConnectsComponents.module.scss';

type SectionHeadingProps = {
  text: string;
};

export const SectionHeading = memo(({text}: SectionHeadingProps) => {
  return <h3 className={styles.sectionHeading}>{text}</h3>;
});
SectionHeading.displayName = 'SectionHeading';
