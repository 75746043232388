import {Outlet} from 'react-router-dom';
import {PageContainer} from 'components/PageContainer/RevisedPageContainer';
import {useCallback, useEffect, useState} from 'react';
import {ProfileModal} from 'components/ProfileModal/ProfileModal';
import {useProfiles} from 'api/User/useProfiles';
import {getUserDisplayName} from 'utils/utils';
import TocaLogo from '../assets/img/icons/toca-logo-black.png';

export default function NavbarLayout() {
  const [profileModalOpen, setProfileModalOpen] = useState(false);
  const {
    currentProfile: {playerCardInfo, firstName, lastName},
  } = useProfiles();
  const profileImageSrc = playerCardInfo?.imageSrc;
  const profileName = getUserDisplayName(firstName, lastName);
  const closeModal = useCallback(() => {
    setProfileModalOpen(false);
  }, []);

  const toggleModal = useCallback(() => {
    setProfileModalOpen((prev) => !prev);
  }, []);

  return (
    <PageContainer zigzag={false}>
      <ProfileModal isOpen={profileModalOpen} setClosed={closeModal} />
      <div className="mt-12 flex min-h-[100dvh] flex-col text-mls-primary">
        <header className="fixed left-0 right-0 top-0 z-50 flex items-center justify-between rounded-b-sm bg-black p-2">
          <div className="ml-4 flex items-center justify-center text-base text-white">Hi, {profileName}!</div>
          <button
            onClick={toggleModal}
            className="mr-2 overflow-hidden rounded-full focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-black"
          >
            {profileImageSrc ? (
              <img src={profileImageSrc} alt="Profile Picture" className="h-10 w-10 rounded-full object-cover" />
            ) : (
              <div className="flex h-10 w-10 items-center justify-center bg-white">
                <img src={TocaLogo} className="w-8 object-cover" />
              </div>
            )}
          </button>
        </header>
        <Outlet />
      </div>
    </PageContainer>
  );
}
