import {ReactComponent as GoogleIcon} from '../../assets/img/icons/redesign-icons/logo-google.svg';
import {ReactComponent as MyTocaLogo} from '../../assets/img/my-toca-logo-white.svg';
import {useNavigate} from 'react-router-dom';
import {signInWithGoogle} from '../../firebase/config';
import {setAccessToken, setCurrentAccountId} from '../../user/user-utils';
import {logLoginEvent} from '../../common/analytics-events';
import {handlePostSignUp} from '../../firebase/auth-utils';

export const MlsAuthLanding = () => {
  const navigate = useNavigate();
  const onSignIn = () => {
    navigate('/auth/signin');
  };

  const onCreateAccount = () => {
    navigate('/auth/signup');
  };

  const onGoogleAuth = async () => {
    const userCredential: any = await signInWithGoogle();
    const authUser = userCredential.user;

    setAccessToken((await authUser.getIdTokenResult()).token);
    setCurrentAccountId(authUser.uid);

    logLoginEvent(userCredential.providerId || 'socialAuth', {
      userUid: userCredential?.user?.uid,
      operationType: userCredential?.operationType,
    });

    const route = await handlePostSignUp(userCredential);
    navigate(route);
  };

  return (
    <div className="bg-black">
      <div className="flex max-w-md mx-auto text-white min-h-screen items-center justify-center px-6">
        <div className="flex flex-col items-center w-full max-w-md -mt-40">
          <MyTocaLogo className="w-32 h-12" />
          <div className="mt-20 text-center">
            <div className="text-[#8DFF69] mt-6 text-4xl md:text-6xl font-bold font-teko">MORE REPS</div>
            <div className="text-[#9E85F7] mt-6 text-4xl md:text-6xl font-bold font-teko">MORE LEVELS</div>
            <div className="text-[#4DD3F5] mt-6 text-4xl md:text-6xl font-bold font-teko">MORE PROGRESS</div>
          </div>

          <div className="fixed bottom-6 flex flex-col px-6 w-full md:min-w-[400px] md:max-w-[400px] space-y-4 md:space-y-6">
            <button
              onClick={onGoogleAuth}
              className="flex items-center justify-center space-x-2 p-3 rounded-full bg-white text-black"
            >
              <GoogleIcon />
              <span className="text-black ml-4 text-base">Continue with Google</span>
            </button>
            <button onClick={onSignIn} className="p-2 w-full text-white text-base border border-white rounded-full">
              Sign In
            </button>
            <button onClick={onCreateAccount} className="p-2 text-white text-base border border-white rounded-full">
              Create an account
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
