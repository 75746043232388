import {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {ArrowButton} from 'components/Buttons/ArrowButton';
import {BasePhoneVerification} from 'pages/SignInSignUp/PhoneNumberAddVerification/BasePhoneVerification';
import {PhoneAuthProvider, signInWithCredential} from 'firebase/auth';
import {auth as fireBaseAuth} from '../../firebase/config';
import {setAccessToken, setCurrentAccountId} from 'user/user-utils';
import {logLoginEvent} from 'common/analytics-events';
import {handlePostSignUp} from '../../firebase/auth-utils';
export const MLSAuthPhoneSignIn = () => {
  const {state} = useLocation();
  const {phoneNumber: initialPhoneNumber} = state || {};
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const handleVerificationComplete = async (phoneNumber: string, verificationId: string, verificationCode: string) => {
    try {
      const credential = PhoneAuthProvider.credential(verificationId, verificationCode);
      const userCredential: any = await signInWithCredential(fireBaseAuth, credential);
      const isNewAccount = userCredential._tokenResponse.isNewUser;
      const {user} = userCredential;
      setAccessToken(user.accessToken);
      setCurrentAccountId(user.uid);
      if (isNewAccount) {
        // this will rarely happen, just handling it in case it happens
        const route = await handlePostSignUp(userCredential);
        navigate(route);
      } else {
        navigate('/profiles');
      }
      logLoginEvent(isNewAccount ? 'phone-signup' : 'phone-login', {
        userUid: userCredential?.user?.uid,
        operationType: userCredential?.operationType,
      });
    } catch (error) {
      console.error(error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-black flex flex-col items-center justify-center text-white p-4">
      <ArrowButton
        className="fixed top-2 left-2 mb-8 -ml-2"
        onClick={() => navigate('/auth/signin')}
        variant="secondary"
        arrowDirection="left"
      />
      <BasePhoneVerification
        phoneNumber={initialPhoneNumber}
        phoneInputHeader={
          <>
            <h1 className="text-4xl font-teko text-center font-bold">Sign in with phone number</h1>
            <p className="mt-4 font-helvetica font-thin text-base mb-8 text-center">
              Enter your phone number to confirm and verify your account
            </p>
          </>
        }
        verificationCodeHeader={({phoneNumber}) => (
          <>
            <h1 className="text-4xl font-teko text-center font-bold mb-2">ENTER YOUR VERIFICATION CODE</h1>
            <p className="mt-2 font-helvetica font-thin text-base mb-8 text-center">A code was sent to {phoneNumber}</p>
          </>
        )}
        phoneSubmitButton={({isSubmitting, onSubmit, hasError}) => (
          <ArrowButton
            className="fixed bottom-8 right-4"
            onClick={onSubmit}
            loading={isSubmitting}
            arrowDirection="right"
            variant="primary"
            hasError={hasError}
          />
        )}
        verificationSubmitButton={({isSubmitting, onSubmit, hasError}) => (
          <ArrowButton
            className="fixed bottom-8 right-4"
            onClick={onSubmit}
            loading={isSubmitting}
            arrowDirection="right"
            variant="primary"
            hasError={hasError}
          />
        )}
        onVerificationComplete={handleVerificationComplete}
      />
    </div>
  );
};
