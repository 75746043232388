import {Session} from './Session';
import {UpcomingSession} from './UpcomingSession';
import {useProfiles} from 'api/User/useProfiles';
import {Appointment, useGetPlayerAppointments} from 'api/MLSChallenge/useGetPlayerAppointments';
import {AppointmentResponse} from 'api/MLSChallenge/useGetPlayerAppointments';
import Loader from 'components/MLS/Loader/Loader';

export const MySessions = () => {
  const {currentProfile} = useProfiles();
  const {data, isLoading} = useGetPlayerAppointments({playerProfileId: currentProfile?._id}) as {
    data?: AppointmentResponse;
    isLoading: boolean;
  };

  const pastAppointments =
    data?.pastAppointments?.appointments?.filter((appointment) => appointment.sessionSummary) ?? [];
  const futureAppointments = data?.futureAppointments?.appointments ?? [];

  return (
    <div className="w-full">
      <>
        <h2 className="text-xl font-semibold">My Sessions</h2>
        {isLoading ? (
          <div className="flex min-h-20 items-center justify-center">
            <Loader />
          </div>
        ) : (
          <>
            {pastAppointments.length > 0 && (
              <div className="mt-4 space-y-6">
                {pastAppointments.map((appointment: Appointment) => (
                  <Session key={appointment._id} appointment={appointment} />
                ))}
              </div>
            )}
            {futureAppointments.length > 0 && (
              <div className="mt-6">
                <UpcomingSession appointment={futureAppointments[0]} />
              </div>
            )}
            {pastAppointments.length === 0 && futureAppointments.length === 0 && (
              <p className="mt-4 min-h-20 rounded-lg bg-mls-grey p-6 text-base">No sessions found</p>
            )}
          </>
        )}
      </>
    </div>
  );
};
