import {memo, useCallback} from 'react';
import styles from './FkoPlayersListModals.module.scss';
import {Link, useNavigate} from 'react-router-dom';
import {addFkoAppointment, addFkoPlayer, FkoPlayer, removeFkoPlayer} from 'redux/slices/fkoFormData';
import {useAppDispatch, useAppSelector} from 'redux/reduxUtils';
import {FormButton} from 'components/Forms/FormButton/FormButton';
import {CreatePlayerForm, CreatePlayerFormType} from 'pages/RegistrationPlayers/CreatePlayerForm';
import moment from 'moment';
import {locationAllowsBookings} from 'feature-flags/booking-locations';
import {useGetSport} from 'common/useGetSport';
import {updateProfileFkoApi} from 'api/api';

type EditOptionsModalProps = {
  closeModal: () => void;
  openEditPlayerModal: (player: FkoPlayer) => void;
  player: FkoPlayer;
};
export const EditOptionsModal = memo(({closeModal, player, openEditPlayerModal}: EditOptionsModalProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {basePath} = useGetSport();
  const isEditable = !player.accountHolder && !player?._id && !player.mboDetails?.clientId;

  const rescheduleTime = useCallback(() => {
    navigate(`${basePath}/schedule/?playerId=${player.playerId}`);
    closeModal();
  }, [navigate, basePath, player.playerId, closeModal]);

  const clearScheduledTime = useCallback(() => {
    if (!player.error && player.selectedSession) {
      dispatch(addFkoAppointment({session: player.selectedSession}));
    }
    dispatch(
      addFkoPlayer({
        ...player,
        selectedSession: undefined,
      })
    );
    closeModal();
  }, [dispatch, player, closeModal]);

  return (
    <div className={styles.editOptionsModalContainer}>
      {isEditable && (
        <FormButton isPrimary onClick={() => openEditPlayerModal(player)}>
          Edit Player Info
        </FormButton>
      )}
      <FormButton isPrimary onClick={rescheduleTime}>
        Change Scheduled Time
      </FormButton>
      <FormButton isPrimary onClick={clearScheduledTime}>
        Clear Scheduled Time
      </FormButton>
      <FormButton onClick={closeModal}>Cancel</FormButton>
      <div className={styles.spacer} />
    </div>
  );
});
EditOptionsModal.displayName = 'EditOptionsModal';

type EditPlayerModalProps = {
  closeModal: () => void;
  player: FkoPlayer;
};
export const EditPlayerModal = memo(({closeModal, player}: EditPlayerModalProps) => {
  const dispatch = useAppDispatch();
  const {siteId} = useAppSelector((state) => state.fkoFormDataReducer);

  const removePlayerOnSubmit = useCallback(() => {
    dispatch(removeFkoPlayer(player));
    closeModal();
  }, [dispatch, closeModal, player]);

  return (
    <>
      <CreatePlayerForm
        defaultValues={player}
        submitButtonText="UPDATE PLAYER DETAILS"
        additionalOnSubmit={closeModal}
        siteId={player.siteId ?? player.mboDetails?.siteId ?? siteId}
      />
      <div className={styles.spacer} />
      <FormButton onClick={removePlayerOnSubmit}>Remove as a player</FormButton>
    </>
  );
});
EditPlayerModal.displayName = 'EditPlayerModal';

export const EditPlayerBirthdateModal = memo(({closeModal, player}: EditPlayerModalProps) => {
  const {siteId} = useAppSelector((state) => state.fkoFormDataReducer);

  const updatePlayerAndClose = async (player?: CreatePlayerFormType) => {
    if (player?.email) {
      await updateProfileFkoApi({
        email: player.email,
        profileId: player._id,
        siteId: player.siteId ?? siteId,
        update: {
          dob: player.dob,
        },
      });
    }
    closeModal();
  };

  return (
    <>
      <CreatePlayerForm
        defaultValues={player}
        submitButtonText="UPDATE PLAYER"
        additionalOnSubmit={updatePlayerAndClose}
        siteId={player.siteId ?? player.mboDetails?.siteId ?? siteId}
      />
    </>
  );
});
EditPlayerBirthdateModal.displayName = 'EditPlayerBirthdateModal';

type AddPlayerModalProps = {
  closeModal: () => void;
  siteId: string;
};
export const AddPlayerModal = memo(({closeModal, siteId}: AddPlayerModalProps) => {
  return (
    <>
      <CreatePlayerForm additionalOnSubmit={closeModal} siteId={siteId} showAccountHolderCheckbox={true} />
      <div className={styles.spacer} />
      <FormButton onClick={closeModal}>Cancel</FormButton>
    </>
  );
});
AddPlayerModal.displayName = 'AddPlayerModal';

type IneligibilityModalProps = {
  closeModal: () => void;
  ineligiblePlayer: FkoPlayer;
};

export const IneligibilityModal = memo(({closeModal, ineligiblePlayer}: IneligibilityModalProps) => {
  const navigate = useNavigate();
  const {sessionTypeName} = useGetSport();

  // TODBAT locationallowsbookings
  const navToBookings =
    ineligiblePlayer.location && locationAllowsBookings(ineligiblePlayer.location.siteId)
      ? () => navigate('/bookings')
      : undefined;

  const [bookedFkoDates, completedFkoDates] = [
    ineligiblePlayer.eligibility?.meta?.bookedVisitsDates,
    ineligiblePlayer.eligibility?.meta?.showVisitsDates,
  ];
  const bookedFkoDate = bookedFkoDates?.length ? moment(bookedFkoDates[0]).format('MMM D, YYYY') : undefined;
  const completedFkoDate = completedFkoDates?.length
    ? moment.max(completedFkoDates.map((date) => moment(date))).format('MMM D, YYYY')
    : undefined;
  const isIncorrectLocation = ineligiblePlayer.eligibility?.meta?.isIncorrectLocation;

  const eligibilityText = bookedFkoDate
    ? `Your ${sessionTypeName} is booked for ${bookedFkoDate}. We are looking forward to seeing you!`
    : completedFkoDate
    ? `It looks like you already completed a ${sessionTypeName} in the past, the last one on ${completedFkoDate}. Unfortunately, you are not eligible to book any additional ${sessionTypeName}s at the moment.`
    : isIncorrectLocation
    ? `This player is registered to a different TOCA location`
    : `Only players that haven’t had a training session yet are eligible for a ${sessionTypeName}.`;

  return (
    <>
      <p className={styles.ineligibleText}>{eligibilityText}</p>
      {navToBookings && (
        <p className={styles.ctaText}>
          You can still book sessions though! Click below to explore other available options.
        </p>
      )}
      <div className={styles.buttonsMargin}>
        {navToBookings && (
          <>
            <FormButton isPrimary onClick={navToBookings}>
              View Regular Bookings
            </FormButton>
            <div className={styles.buttonSpacer} />
          </>
        )}
        <FormButton onClick={closeModal}>Cancel</FormButton>
      </div>
    </>
  );
});
IneligibilityModal.displayName = 'IneligibilityModal';

export const ErrorModal = memo(
  ({errorText, showRawErrorText = false}: {errorText?: string; showRawErrorText?: boolean}) => {
    const {basePath} = useGetSport();

    const modifiedErrorText = errorText?.includes('available')
      ? 'Apologies, but the selected time is no longer available. This happens sometimes, as our free baseline sessions are in high demand and fill quickly. Please select a different time and try again!'
      : undefined;

    return (
      <>
        <p className={styles.errorText}>{showRawErrorText ? errorText : modifiedErrorText}</p>
        <div className={styles.errorSpacer}>
          <Link className={styles.helpLink} to={`${basePath}/help`}>
            Please click here for support
          </Link>
        </div>
      </>
    );
  }
);
ErrorModal.displayName = 'ErrorModal';
