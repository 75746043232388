import {PlayerCardBackground} from 'components/MLS/PlayerCardBackground/PlayerCardBackground';
import {useEffect, RefObject} from 'react';
import {ReactComponent as AvatarPlaceholder} from '../../assets/img/icons/redesign-icons/avatar-placeholder.svg';
import AvatarProfile from '../../assets/img/fc-dallas-logo.png';
import {useColors, usePatterns} from 'hooks/MLSChallenge/usePatterns';

interface AvatarPreviewProps {
  photoUrl: string | null;
  colorIndex: number;
  patternIndex: number | null;
  // canvasRef: RefObject<HTMLCanvasElement>;
}

const AvatarPreview = ({photoUrl, colorIndex, patternIndex}: AvatarPreviewProps) => {
  // useEffect(() => {
  //   const canvas = canvasRef.current;
  //   if (!canvas) return;
  //   // Set canvas dimensions
  //   canvas.width = canvas.offsetWidth;
  //   canvas.height = canvas.offsetWidth; // Make it square
  //   const ctx = canvas.getContext('2d');
  //   if (!ctx) return;
  //   // Clear canvas
  //   ctx.clearRect(0, 0, canvas.width, canvas.height);
  //   const drawDiamond = (width: number, fill = false) => {
  //     const centerX = canvas.width / 2;
  //     const centerY = canvas.height / 2;
  //     ctx.save();
  //     ctx.beginPath();
  //     ctx.moveTo(centerX, centerY - width / 2); // Top
  //     ctx.lineTo(centerX + width / 2, centerY); // Right
  //     ctx.lineTo(centerX, centerY + width / 2); // Bottom
  //     ctx.lineTo(centerX - width / 2, centerY); // Left
  //     ctx.closePath();
  //     if (fill) {
  //       ctx.clip();
  //     } else {
  //       ctx.stroke();
  //     }
  //   };
  //   const diamondSize = Math.min(canvas.width, canvas.height) * 0.8;
  //   drawDiamond(diamondSize);
  //   // Load and draw pattern first
  //   if (patternUrl) {
  //     const patternImg = new Image();
  //     patternImg.onload = () => {
  //       ctx.drawImage(patternImg, 0, 0, canvas.width, canvas.height);
  //     };
  //     patternImg.src = patternUrl;
  //   }
  //   if (photoUrl) {
  //     const img = new Image();
  //     img.onload = () => {
  //       const diamondSize = Math.min(canvas.width, canvas.height) * 0.8;
  //       drawDiamond(diamondSize, true);
  //       const scale = diamondSize / Math.min(img.width, img.height);
  //       const width = img.width * scale;
  //       const height = img.height * scale;
  //       const x = (canvas.width - width) / 2;
  //       const y = (canvas.height - height) / 2;
  //       ctx.drawImage(img, x, y, width, height);
  //       ctx.restore();
  //     };
  //     img.src = photoUrl;
  //   }
  // }, [photoUrl, patternUrl, canvasRef]);
  // return (
  //   <div className="rounded-xl overflow-hidden mb-6">
  //     <canvas ref={canvasRef} className="w-full" />
  //   </div>
  // );

  const colors = useColors();
  const color = colors[colorIndex];

  // const patterns = usePatterns();
  // const pattern = patternIndex ? patterns[patternIndex] : null;

  return (
    <PlayerCardBackground
      // patternIndex={patternIndex}
      // width="327px"
      // height="530px"
      colorIndex={colorIndex}
      // patternIndex={2}
      {...(patternIndex !== null ? {patternIndex} : {})}
      // {...(colorIndex !== null ? {colorIndex} : {})}
    >
      <div className="relative flex h-full w-full flex-col items-center justify-center py-32 sm:py-40">
        <div
          className="relative mb-4 h-48 w-48 rotate-45 transform overflow-hidden"
          style={{border: `2px solid white`}}
        >
          {photoUrl && (
            <div className="absolute inset-0 -rotate-45 scale-150 transform">
              <img src={photoUrl || '/placeholder.svg'} className="h-full w-full object-cover" />
            </div>
          )}
        </div>
      </div>
    </PlayerCardBackground>
  );
};

export default AvatarPreview;
