import {useMemo} from 'react';
import {NavHeading} from '../NavHeading/NavSection';
import {ReactComponent as FirstRankMedal} from '../../../assets/img/mls/first-rank-medal.svg';
import {ReactComponent as SecondRankMedal} from '../../../assets/img/mls/second-rank-medal.svg';
import {ReactComponent as ThirdRankMedal} from '../../../assets/img/mls/third-rank-medal.svg';
import {useProfiles} from 'api/User/useProfiles';
import {useGetLeaderboardList} from 'api/Leaderboards/useGetLeaderboardList';
import {useGetLeaderboardRankings} from 'api/Leaderboards/useGetLeaderboardRankings';
import Loader from 'components/MLS/Loader/Loader';
import MyTocaLogo from '../../../assets/img/mls/my-toca-logo-black.svg';

const DiamondImage = ({
  imageUrl,
  borderColor = '#000000',
  containerClassNames = '',
}: {
  imageUrl: string;
  borderColor?: string;
  containerClassNames?: string;
}) => {
  return (
    <div
      className={`relative mb-6 rotate-45 transform overflow-hidden border-4 ${containerClassNames}`}
      style={{borderColor}}
    >
      <div className="absolute inset-0 -rotate-45 scale-150 transform">
        {imageUrl ? (
          <img src={imageUrl} alt="player avatar" className="h-full w-full object-cover" />
        ) : (
          <div className="flex h-full w-full items-center justify-center bg-white">
            <img src={MyTocaLogo} className="w-[70%]" />
          </div>
        )}
      </div>
    </div>
  );
};

const LeaderboardEntry = ({
  imageUrl,
  borderColor,
  medal: Medal,
  name = 'Unknown Player',
  containerClassNames,
}: {
  imageUrl: string;
  borderColor: string;
  medal: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  name?: string;
  containerClassNames: string;
}) => (
  <div className="flex flex-col items-center">
    <DiamondImage imageUrl={imageUrl} borderColor={borderColor} containerClassNames={containerClassNames} />
    <Medal className="mt-5 h-10 w-10" />
    <div className="mt-3 text-sm font-semibold">{name}</div>
  </div>
);

export const Leaderboard = () => {
  const {currentProfile} = useProfiles();
  const {data, isLoading: leaderboardLoading} = useGetLeaderboardList({
    mboClientId: currentProfile?.mboDetails?.clientId,
    locationId: currentProfile?.mboDetails?.siteId,
  });

  const isPlayerInMultipleLeaderboards = (data?.length ?? 0) > 1;
  const leaderboardId = data?.[0]?.leaderboard?._id;

  const {data: firstLeaderboardRankings, isLoading: rankingsLoading} = useGetLeaderboardRankings({leaderboardId});

  const isLoading = leaderboardLoading || (rankingsLoading && leaderboardId);
  const href = isPlayerInMultipleLeaderboards ? '/leaderboards' : `/leaderboards/${leaderboardId}`;

  const getTopThreeRankings = useMemo(() => {
    if (!firstLeaderboardRankings?.rankings) {
      return [];
    }

    const topThreeRankings = [...firstLeaderboardRankings.rankings]
      .sort((a, b) => a.overallRank - b.overallRank)
      .slice(0, 3);

    const reOrderedRankingsBasedOnDisplayOrder = [topThreeRankings[1], topThreeRankings[0], topThreeRankings[2]];

    return reOrderedRankingsBasedOnDisplayOrder.map((ranking, index) => ({
      imageUrl: '',
      borderColor: ['#A6A6AE', '#C0A869', '#AE7D5E'][index],
      medal: [SecondRankMedal, FirstRankMedal, ThirdRankMedal][index],
      name: ranking.athleteName,
      containerClassNames: ['w-16 h-16 sm:w-28 sm:h-28', 'w-24 h-24 sm:w-40 sm:h-40', 'w-12 h-12 sm:w-20 sm:h-20'][
        index
      ],
    }));
  }, [firstLeaderboardRankings]);

  if (!isLoading && getTopThreeRankings.length === 0) {
    return null;
  }

  return (
    <div className="w-full">
      <NavHeading title={isPlayerInMultipleLeaderboards ? 'Leaderboards' : 'Leaderboard'} href={href} />
      {isLoading ? (
        <div className="flex min-h-[310px] items-center justify-center">
          <Loader />
        </div>
      ) : (
        <div className="flex w-full items-center justify-between p-2 sm:px-16 sm:pt-8">
          {getTopThreeRankings.map((entry, index) => (
            <LeaderboardEntry key={index} {...entry} />
          ))}
        </div>
      )}
    </div>
  );
};
