interface LoaderProps {
  size?: 'sm' | 'md' | 'lg';
  text?: string;
}

const Loader = ({size = 'md', text}: LoaderProps) => {
  const sizeClasses = {
    sm: 'w-6 h-6 border-2',
    md: 'w-10 h-10 border-2',
    lg: 'w-16 h-16 border-4',
  };

  const textSizeClasses = {
    sm: 'text-xs',
    md: 'text-sm',
    lg: 'text-base',
  };

  return (
    <div className="flex flex-col items-center">
      <div
        className={`inline-block rounded-full ${sizeClasses[size]} animate-spin border-black border-t-transparent`}
      />
      {text && <p className={`mt-2 text-black ${textSizeClasses[size]}`}>{text}</p>}
    </div>
  );
};

export default Loader;
