import {Outlet} from 'react-router-dom';
import {PageContainer} from 'components/PageContainer/RevisedPageContainer';
import {Footer} from 'components/Programs/Footer';

export default function PlainLayout() {
  return (
    <PageContainer>
      {/* ensures Footer is always at screen bottom */}
      <div className="flex flex-col min-h-[100dvh]">
        <Outlet />
        <Footer />
      </div>
    </PageContainer>
  );
}
