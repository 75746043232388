import {useState} from 'react';

type TabOption = {
  label: string;
  content: React.ReactNode;
};

type TabsProps = {
  options: TabOption[];
  defaultIndex?: number;
  onChange?: (tabIndex: number) => void;
  hideContent?: boolean;
  containerClassName?: string;
};

export default function Tabs({options, defaultIndex = 0, onChange, hideContent = false, containerClassName}: TabsProps) {
  const defaultTab = options[defaultIndex];
  const [selected, setSelected] = useState(defaultTab);

  const handleClick = (option: TabOption, index: number) => {
    setSelected(option);
    onChange?.(index);
  };

  return (
    <>
      <div className={` bg-mls-grey flex w-full justify-between rounded-full p-2 ${containerClassName}`}>
        {options.map((option, index) => (
          <button
            key={option.label}
            onClick={() => handleClick(option, index)}
            className={`mx-1 flex-1 rounded-full py-3 text-base font-medium transition-all ${selected.label === option.label ? 'bg-black text-white' : 'text-mls-primary'} `}
          >
            {option.label}
          </button>
        ))}
      </div>
      {!hideContent && <div className="mt-8">{selected.content}</div>}
    </>
  );
}
