import {memo, useEffect, useMemo} from 'react';
import {Helmet} from 'react-helmet';
import {useProfiles} from '../../api/User/useProfiles';
import AvatarProfile from '../../assets/img/avatar-profile.png';
import {useNavigate} from 'react-router-dom';
import {getUserDisplayName} from 'utils/utils';
import {ReactComponent as CheckmarkIcon} from '../../assets/img/icons/redesign-icons/checked-circle-filled.svg';
import {Profile} from 'user/player-info.interface';
import {useGetUser} from 'api/User/useGetUser';
import Spinner from 'components/Loader/Spinner';

export const Profiles = memo(() => {
  const navigate = useNavigate();
  const {currentProfile, otherProfiles, selectProfile} = useProfiles();
  const {data: user, isLoading: userLoading} = useGetUser({maxRetries: 1});

  // Create stable sorted profiles using useMemo so that the order of profiles is consistent in rendering
  const stableProfiles = useMemo(() => {
    const allProfiles = [...(currentProfile ? [currentProfile] : []), ...(otherProfiles || [])];
    return allProfiles;
  }, [userLoading]);

  if (userLoading) {
    return (
      <div className="flex justify-center items-center h-screen bg-black">
        <Spinner darkMode />
      </div>
    );
  }

  const renderProfile = (profile: Profile) => (
    <div
      className="flex flex-row items-center justify-between px-4 py-2 rounded-md border-[1px] my-2 border-[1px]cursor-pointer"
      key={profile._id}
      onClick={() => selectProfile(profile._id)}
    >
      <img
        className="w-16 h-16 rounded-full object-cover"
        src={profile?.avatarUrl || AvatarProfile}
        alt={getUserDisplayName(profile.firstName, profile.lastName)}
      />

      <div className="flex flex-col justify-between ml-4 w-[80%] text-left">
        <p className="text-lg font-semibold mb-2">{getUserDisplayName(profile.firstName, profile.lastName)}</p>
        {profile.dob && <p className="text-sm text-gray-300">{profile.dob}</p>}
        {profile.location?.name && <p className="text-sm text-gray-300">{profile.location.name}</p>}
      </div>

      <div className="h-[24px] w-[24px]">
        {currentProfile?._id === profile._id && <CheckmarkIcon className="h-full w-full rounded-full fill-[green]" />}
      </div>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>MyTOCA | Profiles</title>
      </Helmet>

      <div className="bg-black exceed-global-padding flex min-h-screen flex-col overflow-auto">
        <div className="flex relative flex-col min-h-screen max-w-lg mx-auto flex-1 text-white text-center items-center px-4 w-full">
          <h1 className="text-5xl font-teko font-semibold mt-16 md:mt-20 mb-12 md:mb-36">Welcome Back</h1>

          {currentProfile && currentProfile.playerCardInfo?.imageSrc && (
            <div className="mt-8 md:mt-12 flex flex-col items-center justify-center w-full">
              {/* Diamond Image */}
              <div className="relative mb-4 h-48 w-48 rotate-45 transform overflow-hidden">
                <div className="absolute inset-0 -rotate-45 scale-150 transform">
                  <img
                    src={currentProfile.playerCardInfo.imageSrc}
                    alt={getUserDisplayName(currentProfile.firstName, currentProfile.lastName)}
                    className="h-full w-full object-cover"
                  />
                </div>
              </div>
              <p className="text-4xl font-teko font-semibold mt-12">
                {currentProfile?.firstName} <br />
                {currentProfile?.lastName}
              </p>
            </div>
          )}

          <div className="mt-8 md:mt-12 flex flex-col w-full">{stableProfiles.map(renderProfile)}</div>

          <p className="text-sm opacity-70 font-light">Click on a profile to use it.</p>

          <button
            onClick={() => navigate('/home')}
            className="bg-white text-lg font-semibold text-black px-6 py-3 rounded-md mt-12 hover:bg-gray-100 transition-colors"
          >
            Continue
          </button>
        </div>
      </div>
    </>
  );
});
Profiles.displayName = 'Profiles';
