type ButtonProps = {
  label: string;
  onClick?: () => void;
  className?: string;
  variant?: 'default' | 'dark';
};

export const Button = ({label, onClick, className = '', variant = 'default'}: ButtonProps) => {
  const buttonClass =
    variant === 'dark'
      ? `rounded-full bg-black px-6 py-2 text-lg font-medium text-white ${className}`
      : `rounded-full bg-white px-6 py-2 text-lg font-medium text-black ${className}`;

  return (
    <button onClick={onClick} className={buttonClass}>
      {label}
    </button>
  );
};
