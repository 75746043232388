import {Button} from 'components/MLS/Button/Button';
import {useState, useRef, ChangeEvent} from 'react';
import Webcam from 'react-webcam';

interface PhotoSourceSelectorProps {
  photoPresent: boolean;
  onPhotoSelect: (dataUrl: string) => void;
}

const PhotoSourceSelector = ({photoPresent, onPhotoSelect}: PhotoSourceSelectorProps) => {
  const [cameraActive, setCameraActive] = useState(false);
  const webcamRef = useRef<Webcam>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleCapture = () => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      onPhotoSelect(imageSrc);
      setCameraActive(false);
    }
  };

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file && file.size <= 5 * 1024 * 1024) {
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target?.result) {
          onPhotoSelect(e.target.result as string);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center gap-6">
      <h2 className="mt-4 px-4 text-center text-base text-mls-grey-xdark">
        Upload or take a photo to create your avatar
      </h2>
      <div className="flex flex-col gap-4">
        {cameraActive ? (
          <div className="fixed left-0 top-0 z-100 flex h-full w-full flex-col items-center bg-black">
            <Webcam
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={{facingMode: 'user'}}
              className="h-full w-full"
            />
            <div className="absolute bottom-12 mb-4 flex flex-col gap-4">
              <Button label="Capture Photo" onClick={handleCapture} />
              <Button label="Cancel" onClick={() => setCameraActive(false)} />
            </div>
          </div>
        ) : (
          <div className="mb-4 flex flex-col gap-4">
            <Button
              variant="dark"
              label={photoPresent ? 'Retake Photo' : 'Take Photo'}
              onClick={() => setCameraActive(true)}
            />

            <input type="file" accept="image/*" onChange={handleFileUpload} ref={fileInputRef} hidden />
            <Button
              variant="dark"
              label={photoPresent ? 'Reupload Photo' : 'Upload Photo'}
              onClick={() => fileInputRef.current?.click()}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PhotoSourceSelector;
