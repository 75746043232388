import {memo} from 'react';

type MapDisplayProps = {
  address: string;
};

export const MapDisplay = memo(({address}: MapDisplayProps) => {
  const mapSrc = `https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GMAPS_API_KEY}&q=${encodeURI(
    address
  )}`;

  return (
    <div className="flex grow">
      <iframe
        src={mapSrc}
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        className="flex grow overflow-hidden"
      />
    </div>
  );
});
MapDisplay.displayName = 'MapDisplay';

/* .map {
  display: flex;
  width: 100%;
  height: calc(100vw - 48px);
  max-height: 552px;

  iframe {
    flex: 1;
    border: 1px solid #d3d3d3;
    border-radius: 5.5px;
    overflow: hidden;
  }
} */
