import {memo, useMemo} from 'react';
import {useNavigate} from 'react-router-dom';
import {colors} from 'components/MLS/PlayerCardBackground/PlayerCardBackground';
import {PlayerCardBackground} from 'components/MLS/PlayerCardBackground/PlayerCardBackground';
import {JerseyBack} from 'pages/CustomizeJersey/JerseyBack/JerseyBack';
import MyTocaLogo from '../../../assets/img/mls/my-toca-logo-black.svg';
import {useProfiles} from 'api/User/useProfiles';
import {getAge} from 'user/user-utils';

interface PlayerCardProps {
  containerClassNames?: string;
}

export const PlayerCard = memo(({containerClassNames = ''}: PlayerCardProps) => {
  const {
    currentProfile: {jerseyInfo, playerCardInfo, firstName, lastName, dob, lifeTimeReps = 0},
  } = useProfiles();
  const navigate = useNavigate();
  const borderColor = '#0000001A';
  const jerseyColorIndex = useMemo(() => {
    const colorIndex = colors.findIndex((color) => color.lightColor === jerseyInfo?.jerseyLightColor);
    if (colorIndex === -1) {
      return 0;
    }
    return colorIndex;
  }, [jerseyInfo]);

  const playerCardColorIndex = useMemo(() => {
    const colorIndex = colors.findIndex((color) => color.lightColor === playerCardInfo?.playerCardLightColor);
    if (colorIndex === -1) {
      return 0;
    }
    return colorIndex;
  }, [playerCardInfo]);

  const patternIndex = playerCardInfo?.patternIndex || 0;

  const age = getAge(dob);
  const imgSrc = playerCardInfo?.imageSrc;
  const textColor = colors[playerCardColorIndex].textColor;

  return (
    <div className={`aspect-[327/530] max-h-screen ${containerClassNames}`} onClick={() => navigate('/avatar-creator')}>
      <PlayerCardBackground
        colorIndex={playerCardColorIndex}
        patternIndex={patternIndex}
        containerClassNames="rounded-lg overflow-hidden"
      >
        <div className="h-full p-1">
          <div
            className="relative flex h-full w-full flex-col items-center justify-between rounded-lg p-2"
            style={{border: `1px solid ${borderColor}`, color: textColor}}
          >
            {/* Top Numbers */}
            <div className="flex w-full justify-between">
              <div
                className="flex h-[72px] w-[72px] cursor-pointer items-center justify-center rounded-full bg-white"
                onClick={(event) => {
                  event.stopPropagation();
                  navigate('/customize-jersey');
                }}
              >
                <JerseyBack
                  name={jerseyInfo?.jerseyName || ''}
                  number={jerseyInfo?.jerseyNumber.toString() || ''}
                  isPlayerCard={true}
                  selectedColorIndex={jerseyColorIndex}
                />
              </div>
              <div className="flex h-[72px] w-[72px] items-center justify-center rounded-full bg-white">
                {/* <span className="font-teko text-[40px] font-bold">{jerseyNumber}</span> */}
                <img src={MyTocaLogo} className="w-[56px]" />
              </div>
            </div>

            <div>
              {/* Diamond Image */}
              <div className="relative mb-4 h-48 w-48 rotate-45 transform overflow-hidden">
                <div className="absolute inset-0 -rotate-45 scale-150 transform">
                  {imgSrc ? (
                    <img src={imgSrc} className="h-full w-full object-cover" />
                  ) : (
                    <div className="flex h-full w-full items-center justify-center bg-white">
                      {/* <span className="font-teko text-[40px] font-bold">{jerseyNumber}</span> */}
                      <img src={MyTocaLogo} className="w-40" />
                    </div>
                  )}
                </div>
              </div>

              {/* Name and Level */}
              <div className="mt-10 text-center">
                <h2 className="font-teko text-5xl uppercase text-mls-primary" style={{color: textColor}}>
                  {firstName}
                </h2>
                <h2 className="font-teko text-5xl uppercase text-mls-primary" style={{color: textColor}}>
                  {lastName}
                </h2>
                {/* <div className="text-base opacity-70">
                    Level {level}/{maxLevel}
                  </div> */}
              </div>
            </div>

            {/* Stats */}
            <div className="flex w-full justify-between text-center">
              {/* <div>
                  <div className="text-base opacity-70">Pos</div>
                  <div className="font-teko text-[28px] font-bold">{position}</div>
                </div> */}
              <div>
                <div className="text-base opacity-70">Age</div>
                <div className="font-teko text-[28px] font-bold">{age}</div>
              </div>
              <div>
                <div className="text-base opacity-70">Reps</div>
                <div className="font-teko text-[28px] font-bold">{lifeTimeReps}</div>
              </div>
            </div>
          </div>
        </div>
      </PlayerCardBackground>
    </div>
  );
});

PlayerCard.displayName = 'PlayerCard';
