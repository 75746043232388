import {memo, useCallback, useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {PlayerCard} from './PlayerCard/PlayerCard';
import {Leaderboard} from './Leaderboard/Leaderboard';
import {PersonalBest} from './PersonalBest/PersonalBest';
import {MySessions} from './MySessions/MySessions';
import {useProfiles} from 'api/User/useProfiles';

export const PlayerHome = memo(() => {
  const navigate = useNavigate();
  const {currentProfile} = useProfiles();
  const firstMount = useRef(true);

  useEffect(() => {
    if (firstMount.current && currentProfile) {
      firstMount.current = false;
      if (!currentProfile?.playerCardInfo || !currentProfile?.playerCardInfo?.imageSrc) {
        navigate('/avatar-creator');
      } else if (!currentProfile?.jerseyInfo || !currentProfile?.jerseyInfo?.jerseyName) {
        navigate('/customize-jersey');
      }
    }
  }, [currentProfile, navigate]);

  return (
    <div className="flex flex-col items-center justify-center gap-8 py-8" data-test-id="player-home-container">
      <PlayerCard containerClassNames="w-[327px] h-[530px] cursor-pointer" />
      <Leaderboard />
      {/* <PersonalBest /> */}
      <MySessions />
    </div>
  );
});

PlayerHome.displayName = 'PlayerHome';
