import {useNavigate, useParams} from 'react-router-dom';
import {LeaderboardPageContainer} from '../LeaderboardPageContainer/LeaderboardPageContainer';
import {LeaderboardDetails} from './LeaderboardDetails';
import styles from './LeaderboardDetails.module.scss';
import {LoadingAnimationFullHeight} from 'components/Loader/LoadingAnimation';
import {useGetLeaderboardRankings} from 'api/Leaderboards/useGetLeaderboardRankings';
import {NewPageHeading} from 'components/MLS/NewPageHeading/NewPageHeading';

export function LeaderboardDetailsData() {
  const navigate = useNavigate();
  const {id: leaderboardId} = useParams<{id: string}>();
  const {data, isLoading} = useGetLeaderboardRankings({leaderboardId});

  if (!data && isLoading) {
    return (
      <LeaderboardPageContainer>
        <LoadingAnimationFullHeight />
      </LeaderboardPageContainer>
    );
  }

  if (!data || !data.leaderboard) {
    return (
      <LeaderboardPageContainer>
        <NewPageHeading
          text="Leaderboards"
          onClickOverride={() => navigate('/leaderboards', {state: {from: `/leaderboards/${leaderboardId}`}})}
        />
        <div className={styles.notFound}>
          <h2>Leaderboard not found</h2>
        </div>
      </LeaderboardPageContainer>
    );
  }

  return <LeaderboardDetails leaderboard={data} />;
}
