import {forwardRef, ReactNode} from 'react';
import styles from './FormCheckbox.module.scss';

export interface FormCheckboxProps extends React.HTMLProps<HTMLInputElement> {
  labelText: string | ReactNode;
  id: string;
}

/**
 * @deprecated this remains as an FKO component, use Checkbox for all other uses
 */
export const FormCheckbox = forwardRef<HTMLInputElement, FormCheckboxProps>((props, ref) => {
  const {id, labelText, ...rest} = props;

  return (
    <label htmlFor={id} className={styles.checkboxLabel}>
      <input ref={ref} className={styles.checkboxInput} id={id} type="checkbox" {...rest} />
      <div className={styles.checkboxDisplay} />
      {labelText}
    </label>
  );
});
FormCheckbox.displayName = 'FormCheckbox';
