import {getLocationBySiteId} from 'constants/locations';
import {Appointment} from 'api/MLSChallenge/useGetPlayerAppointments';

export const UpcomingSession = ({appointment}: {appointment: Appointment}) => {
  const {startDateTime, siteId, durationMinutes, sessionTypeName} = appointment || {};

  const date = startDateTime;
  const locationName = getLocationBySiteId(siteId)?.name;
  const time = startDateTime;

  return (
    <div className="rounded-lg bg-mls-grey p-6">
      <h2 className="mb-8 border-b border-mls-grey-dark pb-6 text-lg font-medium">Upcoming</h2>

      <div className="flex items-center gap-6">
        <div className="flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-full bg-mls-primary text-white">
          <span className="text-xs font-medium">4 PM</span>
        </div>

        <div className="space-y-1">
          <h3 className="text-base font-medium">
            {sessionTypeName} - {date}
          </h3>
          <p className="text-base text-mls-secondary">
            {locationName} • {durationMinutes} min
          </p>
        </div>
      </div>
    </div>
  );
};
