import {Appointment} from 'api/MLSChallenge/useGetPlayerAppointments';
import {CircularProgressBar} from 'components/MLS/CircularProgressBar/CircularProgressBar';
import {getLocationBySiteId} from 'constants/locations';

export interface SessionProps {
  id: string;
  reps: number;
  date: string;
  location: string;
  duration: number;
  stats: {
    accuracy: number;
    speed: number;
    awareness: number;
    passes: number;
  };
}

const StatItem = ({
  value,
  label,
  showProgressBar = false,
}: {
  value: number;
  label: string;
  showProgressBar?: boolean;
}) => {
  return (
    <div className="flex flex-col items-center justify-center gap-4">
      {showProgressBar ? (
        <CircularProgressBar value={value} size={50} strokeWidth={3} />
      ) : (
        <span className="font-teko text-2xl font-bold text-mls-sky-blue">{value}</span>
      )}
      <span className="text-xs text-mls-secondary">{label}</span>
    </div>
  );
};

export const Session = ({appointment}: {appointment: Appointment}) => {
  const {
    sessionSummary: {session: {totalBallsFired = 0, accuracy = 0, streak = 0, mph = 0, speedOfPlay = 0} = {}} = {},
    startDateTime,
    siteId,
    durationMinutes,
    _id,
  } = appointment || {};

  const reps = totalBallsFired;
  const date = startDateTime;
  const locationName = getLocationBySiteId(siteId)?.name;

  return (
    <div key={_id} className="mb-6 w-full rounded-lg bg-mls-grey">
      <div className="p-6">
        {/* Header */}
        <div className="mb-8 flex items-center gap-4 border-b border-mls-grey-dark pb-6">
          <div className="flex h-20 w-20 items-center justify-center rounded-full bg-mls-sky-blue">
            <span className="text-center font-teko text-xl font-bold text-white">
              {reps}
              <br />
              REPS
            </span>
          </div>
          <div>
            <h2 className="text-base text-mls-secondary">{date}</h2>
            <p className="text-base text-mls-secondary">
              {locationName} • {durationMinutes} min
            </p>
          </div>
        </div>

        {/* Stats Grid */}
        <div className="grid grid-cols-4 gap-2">
          <StatItem value={accuracy * 100} label="Accuracy" />
          <StatItem value={streak} label="Streak" />
          <StatItem value={mph} label="Mph" />
          <StatItem value={speedOfPlay} label="Speed of Play" />
        </div>
      </div>
    </div>
  );
};
