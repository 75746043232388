import {Dispatch, SetStateAction, useCallback, useRef} from 'react';
import styles from './LeaderboardSearchBar.module.scss';
import {FormInput} from '../../../../components/Forms/FormInput/FormInput';
import {ReactComponent as ChevronLeft} from '../../../../assets/img/icons/mls-icons/chevron-left.svg';
import {ReactComponent as CloseIcon} from '../../../../assets/img/mls/close-icon.svg';

export const LeaderboardSearchBar = ({
  index,
  indexResults,
  onChange,
  setIndex,
}: {
  index: number;
  indexResults: number[];
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
  setIndex: Dispatch<SetStateAction<number>>;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onNext = useCallback(() => {
    if (inputRef.current?.value) {
      setIndex((prev) => (prev + 1 < indexResults.length ? prev + 1 : 0));
    }
  }, [indexResults.length, setIndex]);

  const onPrevious = useCallback(() => {
    if (inputRef.current?.value) {
      setIndex((prev) => (prev - 1 >= 0 ? prev - 1 : indexResults.length - 1));
    }
  }, [indexResults.length, setIndex]);

  const onClear = useCallback(
    (e: any) => {
      if (inputRef.current) {
        inputRef.current.value = '';
        // ensure rerender
        onChange(e);
      }
    },
    [onChange]
  );

  return (
    <div className={styles.container}>
      <div className={styles.inputContainer}>
        <FormInput onChange={onChange} ref={inputRef} />
      </div>
      <div className={`${styles.resultsContainer} ${inputRef.current?.value && styles.isOpen}`}>
        <div className={styles.resultsText}>
          {indexResults.length ? (
            <p>
              {index + 1} of {indexResults.length}
            </p>
          ) : inputRef.current?.value ? (
            <p>No results</p>
          ) : (
            <p />
          )}
        </div>
        <div className={styles.buttonContainer}>
          <button onClick={onPrevious} className={styles.searchButton}>
            <ChevronLeft />
          </button>
          <button onClick={onNext} className={styles.searchButton}>
            <ChevronLeft className={styles.reverse} />
          </button>
          <button onClick={onClear} className={styles.searchButton}>
            <CloseIcon />
          </button>
        </div>
      </div>
    </div>
  );
};
