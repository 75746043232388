import {MyTocaLogo} from 'components/Logo/MyTocaLogo';
import styles from './ConnectsComponents.module.scss';

export const LogoBanner = () => {
  return (
    <div className={styles.logoBanner}>
      <MyTocaLogo className={styles.tocaLogo} />
    </div>
  );
};
