import {memo} from 'react';
import styles from './FormDividerRevised.module.scss';

export const FormDividerRevised = memo(({text}: {text?: string}) => {
  return (
    <div className={styles.container}>
      <span className={styles.dividerLine} />
      {text && (
        <>
          <p className={styles.dividerText}>{text}</p>
          <span className={styles.dividerLine} />
        </>
      )}
    </div>
  );
});
FormDividerRevised.displayName = 'FormDividerRevised';
