import {forwardRef} from 'react';
import styles from './StandardInput.module.scss';

interface StandardInputProps extends React.HTMLProps<HTMLInputElement> {
  hasError?: boolean;
  variant?: string;
}

export const StandardInput = forwardRef<HTMLInputElement, StandardInputProps>((props, ref) => {
  const {hasError, variant, ...rest} = props;

  return (
    <input
      ref={ref}
      className={`${styles.input} ${variant === 'new' ? styles.new : ''} ${hasError ? styles.errorState : ''}`}
      {...rest}
    />
  );
});
StandardInput.displayName = 'StandardInput';
