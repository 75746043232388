import {Outlet} from 'react-router-dom';
import {PageContainer} from 'components/PageContainer/RevisedPageContainer';
import {Footer} from 'components/Programs/Footer';
import {useAuthStatus} from '../firebase/useAuthStatus.hook';

export default function LeaderBoardLayout() {
  const {loggedIn} = useAuthStatus();
  const publicView = !loggedIn;
  return (
    <PageContainer zigzag={false}>
      {/* ensures Footer is always at screen bottom */}
      <div className="flex min-h-[100dvh] flex-col text-mls-primary">
        <Outlet />
        {publicView && <Footer />}
      </div>
    </PageContainer>
  );
}
