import {PropsWithChildren} from 'react';
import {useProfiles} from '../../api/User/useProfiles';
import {isTrainer} from '../../user/user-utils';

// TODO this can prob be rolled into RootTrainerLayout?
export default function TrainerAuthContainer({children}: PropsWithChildren) {
  const {currentProfile} = useProfiles();

  if (!isTrainer(currentProfile)) {
    return <div>Insufficient permissions</div>;
  }

  return <>{children}</>;
}
