import {memo, ReactNode} from 'react';
import styles from './BaselineContainer.module.scss';
import {use1200Width} from 'hooks/useFullWidth';

export const BaselineContainer = memo(({children}: {children: ReactNode}) => {
  use1200Width();

  return <div className={styles.container}>{children}</div>;
});
BaselineContainer.displayName = 'BaselineContainer';
