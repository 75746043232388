import {useMutation, useQueryClient} from '@tanstack/react-query';
import {
  updatePlayerDetails as updatePlayerDetailsApi,
  updatePlayerAvatar as updatePlayerAvatarApi,
  UpdatePlayerDetailsRequestPayload,
  UpdatePlayerAvatarRequestPayload,
  UpdatePlayerCardResponse,
} from './updatePlayerCard';
import {toast} from 'react-toastify';
import {useCallback} from 'react';

const useUpdatePlayerCard = ({
  playerProfileId,
  onSuccessHandler,
  onErrorHandler,
}: {
  onSuccessHandler?: () => void;
  onErrorHandler?: () => void;
  playerProfileId: string;
}) => {
  const queryClient = useQueryClient();

  const mutateUpdateDetails = useMutation<unknown, unknown, UpdatePlayerDetailsRequestPayload>({
    mutationFn: updatePlayerDetailsApi,
  });

  const mutateUpdateAvatar = useMutation<UpdatePlayerCardResponse, unknown, UpdatePlayerAvatarRequestPayload>({
    mutationFn: updatePlayerAvatarApi,
  });

  const updatePlayerCard = useCallback(
    async (payload: UpdatePlayerDetailsRequestPayload & UpdatePlayerAvatarRequestPayload) => {
      const {patternIndex, playerCardLightColor, playerCardDarkColor, playerCardTextColor, avatarUrl} = payload;
      try {
        console.log('payload', payload);
        let updatedAvatarUrl: string | undefined;
        if (typeof patternIndex === 'number') {
          await mutateUpdateDetails.mutateAsync({
            patternIndex,
            playerCardLightColor,
            playerCardDarkColor,
            playerCardTextColor,
          });
          queryClient.invalidateQueries({queryKey: ['player-card-details', playerProfileId]});
        }
        if (avatarUrl) {
          const {avatarUrl: uploadedAvatarUrl} = await mutateUpdateAvatar.mutateAsync({
            avatarUrl,
          });
          queryClient.invalidateQueries({queryKey: ['player-card-avatar', playerProfileId]});
          updatedAvatarUrl = uploadedAvatarUrl;
        }
        toast.success('Player details updated successfully');
        onSuccessHandler?.();
        return updatedAvatarUrl;
      } catch (error) {
        toast.error('Failed to update player details');
        onErrorHandler?.();
      }
    },
    [mutateUpdateDetails, mutateUpdateAvatar, queryClient, onSuccessHandler, onErrorHandler, playerProfileId]
  );

  return {
    updatePlayerCard,
    isLoading: mutateUpdateDetails.isLoading || mutateUpdateAvatar.isLoading,
  };
};

export default useUpdatePlayerCard;
