import React from 'react';
import {ReactComponent as Pattern1} from '../../../assets/img/mls/player-card-pattern1.svg';
import {ReactComponent as Pattern2} from '../../../assets/img/mls/player-card-pattern2.svg';
import {ReactComponent as Pattern3} from '../../../assets/img/mls/player-card-pattern3.svg';
import {ReactComponent as Pattern4} from '../../../assets/img/mls/player-card-pattern4.svg';
import {ReactComponent as Pattern5} from '../../../assets/img/mls/player-card-pattern5.svg';
import {ReactComponent as Pattern6} from '../../../assets/img/mls/player-card-pattern6.svg';
import {ReactComponent as Pattern7} from '../../../assets/img/mls/player-card-pattern7.svg';
import {ReactComponent as Pattern8} from '../../../assets/img/mls/player-card-pattern8.svg';
import {ReactComponent as Pattern9} from '../../../assets/img/mls/player-card-pattern9.svg';
import {ReactComponent as Pattern10} from '../../../assets/img/mls/player-card-pattern10.svg';

export const colors = [
  {
    darkColor: '#DADADA',
    lightColor: '#E4E4E4',
    textColor: '#222222',
  },
  {
    darkColor: '#ECD86C',
    lightColor: '#FFE869',
    textColor: '#2E280C',
  },
  {
    darkColor: '#E6B85D',
    lightColor: '#FFCD69',
    textColor: '#332F15',
  },
  {
    darkColor: '#F4744D',
    lightColor: '#FF8C69',
    textColor: '#592110',
  },
  {
    darkColor: '#77F64E',
    lightColor: '#8DFF69',
    textColor: '#1D4A0F',
  },
  {
    darkColor: '#7453EC',
    lightColor: '#9E85F7',
    textColor: '#FFFFFF',
  },
  {
    darkColor: '#3FCCF0',
    lightColor: '#4DD3F5',
    textColor: '#113C47',
  },
  {
    darkColor: '#ED263A',
    lightColor: '#F54D5E',
    textColor: '#33090D',
  },
  {
    darkColor: '#723C13',
    lightColor: '#B26C37',
    textColor: '#FFFFFF',
  },
  {
    darkColor: '#000000',
    lightColor: '#1E1E1E',
    textColor: '#FFFFFF',
  },
];

const patterns = [Pattern1, Pattern2, Pattern3, Pattern4, Pattern5, Pattern6, Pattern7, Pattern8, Pattern9, Pattern10];

type PlayerCardBackgroundProps = {
  patternIndex?: number;
  colorIndex: number;
  // width?: string;
  // height?: string;
  children?: React.ReactNode;
  containerClassNames?: string;
};

export const PlayerCardBackground = React.memo(
  ({patternIndex, colorIndex, children, containerClassNames = ''}: PlayerCardBackgroundProps) => {
    const Pattern = typeof patternIndex === 'number' ? patterns[patternIndex] : null;
    const color = typeof colorIndex === 'number' ? colors[colorIndex] : null;
    return (
      <div
        className={`relative h-full w-full ${containerClassNames}`}
        style={
          {
            backgroundColor: color?.lightColor,
            '--light-color': color?.lightColor,
            '--dark-color': color?.darkColor,
          } as React.CSSProperties
        }
      >
        {Pattern && <Pattern className="absolute inset-0 z-0 h-full w-full" />}
        {children}
      </div>
    );
  }
);

PlayerCardBackground.displayName = 'PlayerCardBackground';
