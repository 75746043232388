import {memo, useState} from 'react';
import {useForm} from 'react-hook-form';
import {StandardInput} from 'components/Input/StandardInput/StandardInput';
import {FormSelect, FormSelectOption} from 'components/Forms/FormSelect/FormSelect';
import {ArrowButton} from 'components/Buttons/ArrowButton';
import {createInitialProfile} from 'api/api';
import {useNavigate} from 'react-router-dom';
import {DateTime} from 'luxon';
import {auth as firebaseAuth} from '../../firebase/config';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';

interface UserProfileForm {
  firstName: string;
  lastName: string;
  gender: string;
  dob: string;
  levelOfPlay: string;
}

const ErrorMessage = ({text}: {text: string}) => {
  return text ? <p className="text-mls-error text-center">{text}</p> : null;
};

export const MlsAuthCreateProfile = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const validationSchema = yup.object().shape({
    firstName: yup.string().required('First name is required').trim('Name cannot include leading or trailing spaces'),
    lastName: yup.string().required('Last name is required').trim('Name cannot include leading or trailing spaces'),
    gender: yup.string().required('Gender is required'),
    dob: yup.date().required('Date of birth is required'),
    levelOfPlay: yup.string().required('Level of play is required'),
  });
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<UserProfileForm>({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  const genderOptions: FormSelectOption[] = [
    {id: 'male', value: 'Male', displayValue: 'Male'},
    {id: 'female', value: 'Female', displayValue: 'Female'},
    {id: 'other', value: 'Other', displayValue: 'Other'},
    {id: 'prefer-not-to-say', value: 'Prefer Not To Say', displayValue: 'Prefer Not To Say'},
  ];

  const levelOptions: FormSelectOption[] = [
    {id: 'none', value: 'None', displayValue: 'None'},
    {id: 'recreation', value: 'Recreation', displayValue: 'Recreation'},
    {id: 'competitive-club', value: 'Competitive/Club', displayValue: 'Competitive/Club'},
    {id: 'college', value: 'College', displayValue: 'College'},
    {id: 'pro', value: 'Pro', displayValue: 'Pro'},
  ];

  const onProfileSubmit = async (data: UserProfileForm) => {
    try {
      setIsLoading(true);
      const user = firebaseAuth.currentUser;
      if (!user) {
        throw new Error('Missing user information');
      }

      await createInitialProfile({
        email: user.email,
        firstName: data.firstName,
        lastName: data.lastName,
        dob: data.dob ? DateTime.fromJSDate(new Date(data.dob)).toFormat('MM/dd/yyyy') : undefined,
        gender: data.gender,
        levelOfPlay: data.levelOfPlay,
        ...(user.phoneNumber && {phoneNumber: user.phoneNumber}),
      });

      navigate('/avatar-creator');
      //   navigate(user.emailVerified ? '/home' : '/verify-email');
    } catch (error) {
      console.error(error);
      setErrorMessage('Something went wrong, please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-black text-white min-h-screen flex flex-col px-6">
      <div className="flex flex-1 items-center justify-center">
        <div className="w-full max-w-md">
          <h1 className="mt-4 text-3xl font-teko text-center font-bold mb-6">CREATE YOUR PLAYER PROFILE</h1>

          <form onSubmit={handleSubmit(onProfileSubmit)} className="space-y-6">
            <div>
              <label className="opacity-30 text-sm mb-2 block">First Name</label>
              <StandardInput
                spellCheck="false"
                {...register('firstName', {required: true})}
                id="firstName"
                placeholder="First Name"
              />
              <ErrorMessage text={errors.firstName?.message ? errors.firstName.message : '\u00A0'} />
            </div>

            <div>
              <label className="opacity-30 text-sm mb-2 block">Last Name</label>
              <StandardInput
                spellCheck="false"
                {...register('lastName', {required: true})}
                id="lastName"
                placeholder="Last Name"
              />
              <ErrorMessage text={errors.lastName?.message ? errors.lastName.message : '\u00A0'} />
            </div>

            <div>
              <label className="opacity-30 text-sm mb-2 block">Gender</label>
              <FormSelect {...register('gender')} options={genderOptions} />
              <ErrorMessage text={errors.gender?.message ? errors.gender.message : '\u00A0'} />
            </div>

            <div>
              <label className="opacity-30 text-sm mb-2 block">Birthdate</label>
              <StandardInput
                variant="new"
                spellCheck="false"
                {...register('dob')}
                type="date"
                id="dob"
                placeholder="Date of Birth (mm/dd/yyyy)"
                hasError={!!errors.dob?.message}
                min="1900-01-01"
                max={new Date().toISOString().split('T')[0]}
              />
              <ErrorMessage text={errors.dob?.message ? errors.dob.message : '\u00A0'} />
            </div>

            <div>
              <label className="opacity-30 text-sm mb-2 block">Level of Play</label>
              <FormSelect {...register('levelOfPlay')} options={levelOptions} />
              <ErrorMessage text={errors.levelOfPlay?.message ? errors.levelOfPlay.message : '\u00A0'} />
            </div>

            {errorMessage && <p className="text-mls-error text-center">{errorMessage}</p>}

            <div className="flex justify-end">
              <ArrowButton type="submit" hasError={!!errorMessage} loading={isLoading} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

MlsAuthCreateProfile.displayName = 'MlsAuthCreateProfile';
