import axios from 'axios';
import {simpleLogout} from '../firebase/auth-utils';
import {getAccessToken, getCurrentAccountId, getCurrentProfileId, refreshAccessToken} from '../user/user-utils';

/**
 * Global axios interceptor that for each request:
 *  - Adds the access token to the `Authorization` header.
 *  - Adds the current profile ID as `profileId` header.
 *  - Automatically retries requests that returned with a 401 or 403 status code.
 */
export const AxiosInterceptor = () => {
  axios.interceptors.request.use(
    function (config) {
      const token = getAccessToken();
      config.headers = config.headers || {};
      config.headers.Authorization = token ? `Bearer ${token}` : '';

      // Pass the ID of the current user and selected profile as header to every request.
      // It provides the needed context for some APIs, e.g. when profile specific operations need to be performed
      // (the user session might have multiple profiles associated) or in case of active trainer view.
      const currentAccountId = getCurrentAccountId();
      const currentProfileId = getCurrentProfileId();
      if (currentAccountId) {
        config.headers['x-account-id'] = currentAccountId;
      }
      if (currentProfileId) {
        config.headers['x-profile-id'] = currentProfileId;
      }

      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      // Refresh access token and retry request on 403 error response
      const originalRequest = error.config;
      if (error.response?.status === 403 || error.response?.status === 401) {
        if (!originalRequest._retry) {
          originalRequest._retry = true;
          const accessToken = await refreshAccessToken();
          if (accessToken) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + accessToken;
            return axios(originalRequest);
          }
        } else {
          simpleLogout('unauthorized-response');
        }
      }
      return Promise.reject(error);
    }
  );
};
