interface AvatarCartoonSelectorProps {
  selectedIndex: number | null;
  onCartoonSelect: (dataUrl: string, index: number) => void;
  cartoons: string[];
  showLoader: boolean;
  error?: unknown;
}

export const AvatarCartoonSelector: React.FC<AvatarCartoonSelectorProps> = ({
  selectedIndex,
  cartoons,
  onCartoonSelect,
  showLoader,
  error,
}) => {
  return (
    <div className="flex flex-col gap-6">
      <h2 className="mt-4 text-center text-base text-mls-grey-xdark">Choose a cartoon style</h2>
      <div className="grid grid-cols-3 gap-4 p-4">
        {cartoons.map((cartoon, index) => (
          <div
            key={index}
            onClick={() => onCartoonSelect(cartoon, index)}
            className={`object-covere aspect-square w-full cursor-pointer rounded-sm ${
              selectedIndex === index ? 'border-2 border-mls-primary' : ''
            }`}
          >
            <img src={cartoon} alt="cartoon" className="h-full w-full object-cover" />
          </div>
        ))}

        {showLoader && (
          <>
            {[...Array(5)].map((_, index) => (
              <div key={`loading-${index}`} className="aspect-square w-full rounded-lg bg-avatar-bg transition-all">
                <div className="h-full w-full animate-pulse object-cover" />
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
