import {PlayerCardBackground} from 'components/MLS/PlayerCardBackground/PlayerCardBackground';
import {usePatterns} from 'hooks/MLSChallenge/usePatterns';
import {useColors} from 'hooks/MLSChallenge/usePatterns';

interface PatternAndColorSelectorProps {
  patternIndex: number;
  colorIndex: number;
  onPatternSelect: (patternId: number) => void;
  onColorSelect: (colorId: number) => void;
}

const PatternAndColorSelector = ({
  patternIndex,
  colorIndex,
  onPatternSelect,
  onColorSelect,
}: PatternAndColorSelectorProps) => {
  const patterns = usePatterns();
  const colors = useColors();
  return (
    <div className="flex flex-col gap-6">
      <h2 className="mt-4 px-4 text-center text-base text-mls-grey-xdark">Choose a color and pattern</h2>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col items-center justify-center p-4 lg:px-16">
          <div className="scrollbar-hide flex max-w-[100%] overflow-x-auto">
            <div className="flex gap-4">
              {colors.map((color, index) => (
                <div
                  key={index}
                  className={`flex h-12 w-12 flex-shrink-0 cursor-pointer items-center justify-center rounded-full sm:h-24 sm:w-24`}
                  onClick={() => onColorSelect(index)}
                  style={{
                    border: `1px solid ${index === colorIndex ? 'black' : 'lightgray'}`,
                  }}
                  aria-label={`Select color ${color.lightColor}`}
                >
                  <div
                    className="h-6 w-6 rounded-full sm:h-10 sm:w-10"
                    style={{backgroundColor: color.lightColor}}
                  ></div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-3 gap-4 p-4 sm:grid-cols-5 lg:grid-cols-10">
          {patterns.map((_, index) => (
            <div
              key={index}
              onClick={() => onPatternSelect(index)}
              className={`object-covere aspect-square w-full cursor-pointer rounded-sm ${
                patternIndex === index ? 'border-2 border-mls-primary' : ''
              }`}
            >
              <PlayerCardBackground patternIndex={index} colorIndex={0} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PatternAndColorSelector;
