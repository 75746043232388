import axios from 'axios';
import {player} from 'api/api';

// Split into separate request payloads
export interface UpdatePlayerDetailsRequestPayload {
  patternIndex?: number;
  playerCardLightColor?: string;
  playerCardDarkColor?: string;
  playerCardTextColor?: string;
}

export interface UpdatePlayerAvatarRequestPayload {
  avatarUrl?: string;
}

export interface UpdatePlayerCardResponse {
  avatarUrl?: string;
}

// Split into two separate API calls
export const updatePlayerDetails = async (
  payload: UpdatePlayerDetailsRequestPayload
): Promise<UpdatePlayerCardResponse> => {
  console.log('Updating player details:', payload);

  const response = await axios.put(`${player}/player-card/details`, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.data;
};

export const updatePlayerAvatar = async (
  payload: UpdatePlayerAvatarRequestPayload
): Promise<UpdatePlayerCardResponse> => {
  console.log('Uploading avatar:', payload.avatarUrl);

  const response = await axios.put(`${player}/player-card/avatar`, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  return response.data;
};
