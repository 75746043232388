import './Spinner.style.scss';

const Spinner = ({darkMode = false}: {darkMode?: boolean}) => {
  return (
    <div className="loader">
      <div className={darkMode ? 'sk-chase dark-mode' : 'sk-chase'}>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
        <div className="sk-chase-dot"></div>
      </div>
    </div>
  );
};

export default Spinner;
