import {auth as fireBaseAuth} from './config';
import {getCurrentUser, linkPlayerToAccount} from 'api/api';

export async function simpleLogout(reason?: string) {
  await silentLogout();
  window.location.replace(`/auth${reason ? '?reason=' + reason : ''}`);
}

export async function silentLogout() {
  await fireBaseAuth.signOut();
  localStorage.clear();
}

export async function handlePostSignUp(userCredential: any): Promise<string> {
  const isNewAccount = userCredential._tokenResponse.isNewUser;
  let route = '/home';
  if (isNewAccount) {
    await linkPlayerToAccount();
    try {
      const {data: user} = await getCurrentUser();
      if (user.profiles.length > 0) {
        route = '/profiles';
      } else {
        route = '/auth/profile-creation';
      }
    } catch (error) {
      console.error(error);
      // user endpoint throws error means no profiles found
      route = '/auth/profile-creation';
    }
  }
  return route;
}
