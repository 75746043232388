import {memo, ReactNode, useEffect} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {StandardInput} from 'components/Input/StandardInput/StandardInput';
import {usePhoneVerification} from 'hooks/usePhoneVerification';
import formStyles from '../EmailSignInForm.module.scss';
import {BookingFormButton} from 'components/Buttons/Form/FormButton';
import 'react-international-phone/style.css';
import Spinner from '../../../components/Loader/Spinner';

interface FormTypes {
  phoneNumber: string;
  verificationCode: string;
}

interface ButtonRenderProps {
  isSubmitting: boolean;
  onSubmit: () => void;
  hasError: boolean;
}

interface BasePhoneVerificationProps {
  onVerificationComplete: (phoneNumber: string, verificationId: string, verificationCode: string) => Promise<void>;
  buttonText?: string;
  phoneInputHeader?: React.ReactNode;
  verificationCodeHeader?: (state: {phoneNumber: string}) => React.ReactNode;
  phoneSubmitButton?: (props: ButtonRenderProps) => ReactNode;
  verificationSubmitButton?: (props: ButtonRenderProps) => ReactNode;
  phoneNumber?: string;
}

export const BasePhoneVerification = memo(
  ({
    onVerificationComplete,
    phoneInputHeader,
    verificationCodeHeader,
    buttonText = 'Continue',
    phoneSubmitButton,
    verificationSubmitButton,
    phoneNumber,
  }: BasePhoneVerificationProps) => {
    const {state, handlePhoneVerification, setState, handleVerificationError, clearError} = usePhoneVerification();

    const validationSchema = yup.object().shape({
      phoneNumber: yup
        .string()
        .required('Phone number is required')
        .test('valid-phone', 'Please enter a valid phone number', (value) => {
          if (!value) {
            return false;
          }
          // The library provides properly formatted numbers, so we can be less strict
          return value.startsWith('+') && value.length >= 8;
        }),
      verificationCode: yup.string().matches(/^\d{6}$/, 'Verification code must be 6 digits'),
    });

    const form = useForm<FormTypes>({
      mode: 'onChange',
      resolver: yupResolver(validationSchema),
      defaultValues: {
        phoneNumber: phoneNumber,
      },
    });

    const handleVerificationCodeSubmit = async () => {
      const verificationCode = form.getValues('verificationCode');
      if (!verificationCode) {
        return;
      }
      try {
        setState((prev) => ({...prev, isSubmitting: true, error: ''}));
        if (!state.verificationId) {
          throw new Error('Verification session expired');
        }
        await onVerificationComplete(state.phoneNumber, state.verificationId, verificationCode);
      } catch (error: any) {
        handleVerificationError(error);
      } finally {
        setState((prev) => ({...prev, isSubmitting: false}));
      }
    };

    useEffect(() => {
      if (phoneNumber) {
        setState((prev) => ({...prev, phoneNumber}));
        void handlePhoneVerification(phoneNumber);
      }
    }, [phoneNumber]);

    useEffect(() => {
      const subscription = form.watch((value, {name}) => {
        // Clear state error when any field changes
        if (state.error) {
          clearError();
        }
      });

      return () => subscription.unsubscribe();
    }, [form, state.error, clearError]);

    const ErrorMessage = ({message}: {message: string}) => (
      <p className="!text-[#e64040] text-sm mt-1 mb-2 ml-2 !whitespace-normal !line-clamp-2">{message}</p>
    );

    const renderVerificationSubmitButton = () => {
      if (verificationSubmitButton) {
        return verificationSubmitButton({
          isSubmitting: state.isSubmitting,
          onSubmit: () => form.handleSubmit(handleVerificationCodeSubmit)(),
          hasError: !!state.error || !!form.formState.errors.verificationCode,
        });
      }

      return (
        <BookingFormButton
          type="submit"
          hasError={!!state.error || !!form.formState.errors.verificationCode}
          isPrimary
          disabled={state.isSubmitting || !!form.formState.errors.verificationCode}
        >
          <p className={`${formStyles.buttonText} !text-white`}>
            {state.isSubmitting ? 'Verifying code...' : 'Verify Code'}
          </p>
        </BookingFormButton>
      );
    };

    return (
      <div className="flex flex-col mt-2">
        <div id="recaptcha-container" />

        {!state.showVerificationInput ? (
          // Waiting for recaptcha verification
          <Spinner darkMode />
        ) : (
          <>
            {verificationCodeHeader && verificationCodeHeader(state)}
            <form onSubmit={form.handleSubmit(handleVerificationCodeSubmit)} className={formStyles.container}>
              <StandardInput
                type="text"
                {...form.register('verificationCode')}
                placeholder="Code received"
                hasError={!!form.formState.errors.verificationCode?.message}
                disabled={state.isSubmitting}
              />

              {form.formState.errors.verificationCode?.message && (
                <p className={`mt-1 ml-2 !line-clamp-2 !whitespace-normal !text-[#e64040]`}>
                  {form.formState.errors.verificationCode?.message}
                </p>
              )}

              {state.error && <ErrorMessage message={state.error} />}

              <div className={`${formStyles.buttonWrapper} mt-4`}>{renderVerificationSubmitButton()}</div>
            </form>
          </>
        )}
      </div>
    );
  }
);

BasePhoneVerification.displayName = 'BasePhoneVerification';
