import {ReactNode, useRef} from 'react';
import {createPortal} from 'react-dom';
import styles from './ModalContainer.module.scss';

export type ModalContainerProps = {
  isOpen: boolean;
  onClose?: () => void;
  children: ReactNode;
};

const createElementAndAppendToBody = (id: string) => {
  const el = document.createElement('div');
  el.setAttribute('id', id);
  document.body.appendChild(el);
  return el;
};

export const ModalContainer = ({isOpen, onClose, children}: ModalContainerProps) => {
  const rootEl = useRef(document.getElementById('root'));
  // const closing = useRef(true);

  if (!rootEl.current) {
    rootEl.current = createElementAndAppendToBody('modal-root');
  }

  if (!isOpen) {
    return null;
  }

  return createPortal(
    <div className={styles.container}>
      <div className={styles.backdrop} onClick={onClose} />
      <div className={styles.content}>{children}</div>
      {/* <button onClick={onClose}>Close</button> */}
    </div>,
    rootEl.current
  );
};
