import {useQuery} from '@tanstack/react-query';
import {getCartoonStyles} from './getCartoonStyles';

export const generateCartoonStylesQueryKey = (originalPhotoId: string) => {
  return ['avatar', 'cartoonStyles', originalPhotoId];
};

export const getPhotoId = (photoUrl: string) => {
  if (photoUrl.startsWith('data:')) {
    return photoUrl.split('/').pop()?.slice(0, 10) ?? '';
  }
  return photoUrl.split('/')?.pop() ?? '';
};

export const useGetCartoonStyles = (photoUrl: string | null, enabled: boolean, onQuery?: () => void) => {
  const _photoId = getPhotoId(photoUrl || '');
  return useQuery({
    queryKey: generateCartoonStylesQueryKey(_photoId || ''),
    queryFn: async () => {
      onQuery?.();
      const result = await getCartoonStyles(photoUrl || '');
      return result;
    },
    enabled: enabled,
    // staleTime: 5 * 60 * 1000, // Cache for 5 minutes
  });
};
